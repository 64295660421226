import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CSmartTable,
  CRow,
  CFormInput,
  CForm,
  CButton,
  CFormSelect,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CDropdownDivider,
} from "@coreui/react-pro";
import { useQuery } from "@apollo/client";
import { Product, ExcelFile, importProducts } from "src/api/products";
import { formatCurrency, isBarcode } from "src/helpers/numbers";
import { useFormik } from "formik";
import {
  Entity,
  ExcelRow,
  GraphQLFind,
  GraphQLMeta,
  Operation,
  SearchForm,
} from "src/types";
import { usePagination } from "src/hooks/pagination";
import Api from "src/api";
import { useAdminStore } from "src/store";
import writeXlsxFile from "write-excel-file";
import { queryStringToObject } from "src/helpers/strings";
import { logEvent } from "src/helpers/analytics";
import PropagateModal, {
  PropagateModalForwardedRef,
} from "src/components/PropagateModal";
import BatchChangeModal, {
  BatchChangeModalForwardedRef,
} from "src/components/BatchChangeModal";
import PlanAlert from "src/containers/PlanAlert";
import { dateFormat } from "src/helpers/dates";
import { findPointOfSale } from "src/helpers/stores";
import Pagination from "src/components/Pagination";
import { sortBy, uniqBy } from "lodash";
import AlertDialog, {
  AlertDialogForwardedRef,
} from "src/components/AlertDialog";
import { alphabeticalOrder } from "src/helpers/comparators";
import {
  Cog,
  FileDown,
  FileUp,
  FolderDown,
  Plus,
  TrendingDown,
  TrendingUp,
} from "lucide-react";
import ProductsConfigModal, {
  ProductsConfigModalForwardedRef,
} from "./components/ProductsConfigModal";

const Products = () => {
  const { state, search } = useLocation();
  const propagateModalRef = useRef<PropagateModalForwardedRef>(null);
  const batchChangeModalRef = useRef<BatchChangeModalForwardedRef>(null);
  const alertDialogRef = useRef<AlertDialogForwardedRef>(null);
  const { hasPermission, user, currentCompany } = useAdminStore();
  const querySearch = { ...queryStringToObject(search), ...state };
  const canCreateProduct = hasPermission("CREATE_PRODUCT");
  const canEditProduct = hasPermission("UPDATE_PRODUCT");
  const canSeeProduct = hasPermission("SHOW_PRODUCT", { force: true });
  const fileInputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const [productLoading, setProductLoading] = useState<boolean>(false);
  const configModalRef = useRef<ProductsConfigModalForwardedRef>(null);

  const {
    data: products,
    refetch,
    loading,
  } = useQuery<GraphQLMeta<Product>>(Api.Products.LIST_PRODUCTS, {
    variables: {
      fetchPolicy: "no-cache",
      filters: {
        page: querySearch.page ? Number(querySearch.page) : 1,
        trashed: querySearch.trashed ? Number(querySearch.trashed) : 0,
        search: querySearch.search ?? "",
        sort: querySearch.sort ?? "name",
        companyId: currentCompany?.id,
      },
    },
    onCompleted: ({ data: results }) => {
      if (
        formik.values.search !== "" &&
        results.data.length === 1 &&
        isBarcode(formik.values.search)
      ) {
        logEvent("products.search.barcode-result", {
          product: results.data,
        });

        resetAndSearch({
          page: 1,
          trashed: 0,
          search: "",
        });
        navigate(`/products/${results.data[0].id}`);
      }
    },
  });
  const { page, pageChange, resetAndSearch } = usePagination(
    "products",
    refetch
  );
  const { data: excel, loading: downloading } = useQuery<
    GraphQLFind<ExcelFile>
  >(Api.Products.EXPORT_PRODUCTS, {
    fetchPolicy: "no-cache",
    variables: {
      filters: {
        companyId: currentCompany?.id,
      },
    },
  });

  const downloadFile = (full: boolean) => {
    if (!excel?.data) {
      return;
    }

    const excelData: any[] = [];

    if (full) {
      excelData.push([
        {
          value:
            "Esta es la base de productos completa. No puede ser utilizada para actualizar productos.",
          span: 12,
          align: "center",
        },
      ]);
    }

    excelData.push(full ? excel.data.title : excel.data.title.slice(0, -1));

    excel.data.products.forEach((product) => {
      let restFields: ExcelRow[] = [];

      if (full) {
        restFields = [
          {
            value: dateFormat(product.updatedAt, "dd/MM/yyyy HH:mm"),
            align: "right",
          },
        ];
      }

      excelData.push([
        {
          value: product.id,
          align: "center",
        },
        {
          value: product.sku,
          align: "center",
        },
        {
          value: product.name,
        },
        {
          value: product.description,
        },
        {
          value: product.barcodes?.map(({ barcode }) => barcode).join("; "),
          align: "right",
        },
        {
          value:
            product.suppliers
              .map((s) => s.supplier.name)
              .sort(alphabeticalOrder)
              .join("; ")
              .toUpperCase() ?? "",
          align: "center",
        },
        {
          value: product.category?.name.toUpperCase() ?? "",
          align: "center",
        },
        {
          value: full ? formatCurrency(product.cost) : product.cost,
          align: "right",
        },
        {
          value:
            full === false && currentCompany?.config.calculate === "PROFIT"
              ? ""
              : full
              ? `${product.profit}%`
              : product.profit,
          align: "right",
        },
        {
          value: full ? `${product.tax}%` : product.tax,
          align: "center",
        },
        {
          value:
            full === false && currentCompany?.config.calculate === "PRICE"
              ? ""
              : full
              ? formatCurrency(product.price)
              : product.price,
          align: "right",
        },
        ...restFields,
      ]);
    });

    writeXlsxFile(excelData, {
      fileName: `${excel.data.filename}.xlsx`,
    });
  };

  const importFile = async (e) => {
    if (
      currentCompany?.id &&
      e.target.value !== "" &&
      fileInputRef.current?.files?.[0]
    ) {
      setProductLoading(true);
      const formData = new FormData();

      formData.append("file", fileInputRef.current.files[0]);
      formData.append("companyId", currentCompany.id.toString());

      try {
        const response = await importProducts(formData);

        if (response) {
          propagateModalRef.current?.open([
            ...(response?.changes?.create ?? []),
            ...(response?.changes?.update ?? []),
            ...(response?.changes?.delete ?? []),
          ]);
        } else {
          alertDialogRef.current?.show(
            "Importar Productos",
            "El archivo que has subido no tiene ninguna diferencia con tu listado de productos actual."
          );
        }
      } catch (e: any) {
        logEvent("products.import.error", {
          error: e.message,
        });

        if (e.message === "invalid_file") {
          alert(
            "El archivo es inválido, por favor revisa que sea el correcto."
          );
        } else {
          alert("No se han podido cargar los productos.");
        }
      } finally {
        fileInputRef.current.value = "";
        setProductLoading(false);
      }
    }
  };

  const openFilePrompt = () => fileInputRef.current?.click();

  const formik = useFormik<SearchForm & { sort?: string }>({
    initialValues: {
      search: querySearch.search ?? "",
      trashed: 0,
      sort: "",
    },
    onSubmit: (data) => {
      if (!loading) {
        resetAndSearch(
          {
            page: 1,
            search: data.search,
            trashed: Number(data.trashed),
            sort: data.sort,
          },
          { avoidState: true }
        );
      }
    },
  });

  useEffect(() => {
    if (state?.refetch) {
      refetch();
    }

    resetAndSearch({
      search: formik.values.search,
      page: querySearch.page,
      trashed: 0,
      sort: querySearch.sort ?? "name",
    });

    return () => {
      formik.resetForm();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const restFields: any[] = [];

  if (querySearch.sort === "less-updated") {
    restFields.push({
      key: "updatedAt",
      label: "Fecha Actualización",
      _props: { className: "text-right" },
    });
  }

  return (
    <>
      <PlanAlert />

      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader>
              <CRow className="align-items-center justify-content-center">
                <CCol sm={12} className="px-3">
                  Productos
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CForm onSubmit={formik.handleSubmit} className="mb-3">
                <CRow className="align-items-center justify-content-center">
                  <CCol
                    sm={12}
                    className="row justify-content-end"
                    style={{ flexWrap: "nowrap" }}
                  >
                    <CFormInput
                      placeholder="Buscar por nombre..."
                      name="search"
                      autoFocus
                      onChange={formik.handleChange}
                      defaultValue={formik.values.search}
                      style={{ flex: 1 }}
                    />

                    <CFormSelect
                      name="trashed"
                      value={formik.values.trashed}
                      onChange={formik.handleChange}
                      style={{ flex: 0.2, marginLeft: 12 }}
                    >
                      <option value={-1}>Todos los productos</option>
                      <option value={1}>Productos Eliminados</option>
                      <option value={0}>Productos Activos</option>
                    </CFormSelect>

                    <CFormSelect
                      className="ml-2"
                      style={{ flex: 0.2, marginLeft: 12 }}
                      name="sort"
                      defaultValue={querySearch.sort}
                      onChange={formik.handleChange}
                    >
                      <option value="">Ordenado por</option>
                      <option value="name">Nombre</option>
                      <option value="less-updated">Menos actualizado</option>
                    </CFormSelect>

                    <CDropdown
                      style={{ width: 150 }}
                      variant="btn-group"
                      className="pr-0"
                      alignment="end"
                    >
                      <CButton type="submit" color="primary">
                        Buscar
                      </CButton>

                      <CDropdownToggle color="primary" split />

                      <CDropdownMenu>
                        {(products?.data.data.length ?? 0) > 1 && (
                          <>
                            <CDropdownItem
                              as="button"
                              onClick={() =>
                                batchChangeModalRef.current?.open(
                                  Entity.Product,
                                  0,
                                  Operation.Discount
                                )
                              }
                            >
                              <div className="d-flex gap-2 align-items-center justify-content-between">
                                <TrendingDown size={18} />
                                Reducir precios
                              </div>
                            </CDropdownItem>
                            <CDropdownItem
                              as="button"
                              onClick={() =>
                                batchChangeModalRef.current?.open(
                                  Entity.Product,
                                  0,
                                  Operation.Recharge
                                )
                              }
                            >
                              <div className="d-flex gap-2 align-items-center justify-content-between">
                                <TrendingUp size={18} />
                                Aumentar precios
                              </div>
                            </CDropdownItem>
                            <CDropdownDivider />
                          </>
                        )}

                        {!user?.isAdmin && user?.isP && (
                          <>
                            <CDropdownItem
                              as="button"
                              onClick={(e) => {
                                e.preventDefault();

                                configModalRef.current?.open(
                                  currentCompany?.id ?? 0,
                                  currentCompany?.name ?? "",
                                  "company"
                                );
                              }}
                            >
                              <div className="d-flex gap-2 align-items-center justify-content-between">
                                <Cog size={18} />
                                Configuración de productos
                              </div>
                            </CDropdownItem>
                            <CDropdownDivider />
                          </>
                        )}

                        {canEditProduct && canCreateProduct && (
                          <CDropdownItem
                            as="button"
                            onClick={(e) => {
                              e.preventDefault();

                              openFilePrompt();
                            }}
                          >
                            <div className="d-flex gap-2 align-items-center justify-content-between">
                              <FileUp size={18} />
                              Subir excel
                            </div>
                          </CDropdownItem>
                        )}
                        <CDropdownItem
                          as="button"
                          onClick={(e) => {
                            e.preventDefault();

                            if (!productLoading && !downloading) {
                              downloadFile(false);
                            }
                          }}
                        >
                          <div className="d-flex align-items-center justify-content-between">
                            <FileDown size={18} />
                            Descargar excel
                          </div>
                        </CDropdownItem>
                        {canCreateProduct && (
                          <CDropdownItem
                            as="button"
                            onClick={(e) => {
                              e.preventDefault();

                              if (!productLoading && !downloading) {
                                downloadFile(true);
                              }
                            }}
                          >
                            <div className="d-flex gap-2 align-items-center justify-content-between">
                              <FolderDown size={18} />
                              Descargar base de productos
                            </div>
                          </CDropdownItem>
                        )}

                        {canCreateProduct && (
                          <>
                            <CDropdownDivider />
                            <CDropdownItem href="/#/products/new">
                              <div className="d-flex align-items-center justify-content-between">
                                <Plus size={18} />
                                Crear producto
                              </div>
                            </CDropdownItem>
                          </>
                        )}
                      </CDropdownMenu>
                    </CDropdown>
                  </CCol>
                </CRow>
              </CForm>

              <CSmartTable
                loading={loading}
                itemsPerPage={20}
                items={
                  products?.data.data.map((sale) => ({
                    ...sale,
                    _props: { color: sale.deletedAt ? "danger" : "default" },
                  })) || []
                }
                columns={[
                  { key: "id", label: "ID" },
                  {
                    key: "name",
                    label: "Nombre",
                    _props: { className: "font-weight-bold" },
                  },
                  { key: "barcode", label: "Código de Barra" },
                  { key: "supplier", label: "Proveedor(es)" },
                  {
                    key: "connections",
                    label: "Conexiones",
                    _props: { className: "text-center" },
                  },
                  {
                    key: "cost",
                    label: "Costo",
                    _props: { className: "text-right" },
                  },
                  {
                    key: "profit",
                    label: "Ganancia (%)",
                    _props: { className: "text-right" },
                  },
                  {
                    key: "price",
                    label: "Precio de Venta",
                    _props: { className: "text-right" },
                  },
                  ...restFields,
                ]}
                scopedColumns={{
                  cost: (item: Product) => (
                    <td align="right">{formatCurrency(item.cost)}</td>
                  ),
                  supplier: (item: Product) => (
                    <td>
                      {item.suppliers.length > 0
                        ? item.suppliers
                            .map((s) => s.supplier.name)
                            .sort(alphabeticalOrder)
                            .join(", ")
                        : "-"}
                    </td>
                  ),
                  profit: (item: Product) => (
                    <td align="right">{item.profit?.toFixed(2)} %</td>
                  ),
                  price: (item: Product) => (
                    <td align="right">{formatCurrency(item.price)}</td>
                  ),
                  connections: (item: Product) => {
                    const connections = sortBy(
                      item.connections,
                      "priceList.store.type"
                    );

                    return (
                      <td align="center">
                        {uniqBy(connections, "priceList.store.type").map(
                          (connection, index) => {
                            const currentType = findPointOfSale(
                              connection.priceList.store.type
                            );
                            const StoreIcon = () => currentType?.icon;

                            return (
                              <span
                                key={index}
                                className={
                                  connections.length > 1 && index > 0
                                    ? "ml-1"
                                    : ""
                                }
                              >
                                <StoreIcon />
                              </span>
                            );
                          }
                        )}
                      </td>
                    );
                  },
                  barcode: (item: Product) => (
                    <td>
                      {item.barcodes
                        ?.map(({ barcode }) => barcode)
                        .join(", ") ?? ""}
                    </td>
                  ),
                  updatedAt: (item: Product) => (
                    <td align="right">
                      {dateFormat(item.updatedAt, "dd/MM/yyyy")}
                    </td>
                  ),
                }}
                tableProps={{
                  striped: true,
                  hover: canSeeProduct,
                }}
                clickableRows={canSeeProduct}
                onRowClick={(item) => {
                  if (canSeeProduct) {
                    navigate(`/products/${item.id}`, {
                      state: {
                        ...formik.values,
                        ...querySearch,
                        trashed: true,
                      },
                    });
                  }
                }}
              />

              <Pagination meta={products} page={page} pageChange={pageChange} />

              <CForm>
                <input
                  type="file"
                  style={{ display: "none" }}
                  ref={fileInputRef}
                  onChange={importFile}
                />
              </CForm>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <BatchChangeModal
        onClose={(productIds) => {
          propagateModalRef.current?.open(productIds);
        }}
        ref={batchChangeModalRef}
      />

      <ProductsConfigModal ref={configModalRef} />

      <PropagateModal
        title="Importar Productos"
        subtitle="Los productos han sido importados."
        text="¿Quieres propagar estos cambios a alguna de tus listas de precios?"
        ref={propagateModalRef}
      />

      <AlertDialog ref={alertDialogRef} />
    </>
  );
};

export default Products;
