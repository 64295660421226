import classNames from "classnames";
import { memo } from "react";
import SelectSearch, {
  SelectSearchOption,
  SelectSearchProps,
} from "react-select-search";
import { findPointOfSale } from "src/helpers/stores";
import { normalize } from "src/helpers/strings";

type SmartSelectProps = Omit<
  SelectSearchProps,
  "filterOptions" | "onBlur" | "onFocus"
> & {
  name: string;
  resultsCount?: number;
  emptyValue?: number | string;
  emptyLabel?: string;
  invalid?: boolean;
  style?: any;
  onBlur?: SelectSearchProps["onBlur"];
  onFocus?: SelectSearchProps["onFocus"];
  type?: SmartSelectType;
};

export enum SmartSelectType {
  Default = "default",
  Stores = "stores",
}

const renderStore = (props, option, rest) => {
  const currentType = findPointOfSale(option.type);
  const StoreIcon = () => currentType?.icon;

  return (
    <button
      {...props}
      className={classNames(
        "d-flex align-items-center gap-1 select-search-option",
        {
          "select-search-is-selected": rest.selected,
        }
      )}
    >
      <StoreIcon />

      {option.name}
    </button>
  );
};

const SmartSelect = memo(
  ({
    debounce = 300,
    emptyLabel,
    emptyValue = -1,
    name,
    options,
    resultsCount = 6,
    search,
    onBlur,
    onFocus,
    type = SmartSelectType.Default,
    ...props
  }: SmartSelectProps) => {
    const handleBlur = (e: Event) => {
      if (onBlur) {
        onBlur?.(e);
      }
    };

    const handleFocus = (e: Event) => {
      if (onFocus) {
        onFocus?.(e);
      }
    };

    if (type === SmartSelectType.Stores) {
      props.renderOption = renderStore;
    }

    return (
      <SelectSearch
        {...props}
        onBlur={handleBlur}
        onFocus={handleFocus}
        debounce={debounce}
        emptyMessage={emptyLabel}
        options={options}
        search={search}
        fuzzySearch={search}
        filterOptions={
          search
            ? [
                (items: SelectSearchOption[], q: string) => {
                  if (!search) {
                    return items;
                  }

                  return items
                    .filter((item) =>
                      normalize(item.name)
                        .toLowerCase()
                        .includes(q.toLowerCase())
                    )
                    .slice(0, resultsCount);
                },
              ]
            : []
        }
      />
    );
  }
);

export type { SelectSearchOption };
export default SmartSelect;
