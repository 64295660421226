import {
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  CButton,
  CModal,
  CModalHeader,
  CModalFooter,
  CModalBody,
  CCol,
  CRow,
  CFormCheck,
} from "@coreui/react-pro";
import Api from "src/api";
import { useMutation, useQuery } from "@apollo/client";
import { ForwardedModalProps, GraphQLMeta } from "src/types";
import { PriceList } from "src/api/price-lists";
import { useLocation, useNavigate } from "react-router-dom";
import { StoreType } from "src/api/stores";
import FeatureAlert from "src/containers/FeatureAlert";
import { useAdminStore } from "src/store";
import { findPointOfSale } from "src/helpers/stores";
import {
  getCompanyPaymentStatus,
  getStorePaymentStatus,
} from "src/helpers/payments";
import { PaymentStatus } from "src/api/companies";

type FilteredPriceLists = {
  priceListsWithItem: PriceList[];
  priceListsWithoutItem: PriceList[];
};

const SuccessModal: ForwardRefRenderFunction<
  ForwardedModalProps,
  { edit?: boolean }
> = ({ edit = false }, ref) => {
  const { currentCompany } = useAdminStore();
  const successButtonRef = useRef<HTMLButtonElement>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [productId, setProductId] = useState<number>();
  const [priceListIds, setPriceListIds] = useState<number[]>([]);
  const navigate = useNavigate();
  const location = useLocation();

  const { data: allPriceLists } = useQuery<GraphQLMeta<PriceList>>(
    Api.PriceLists.LIST_PRICE_LISTS,
    {
      fetchPolicy: "no-cache",
      variables: {
        filters: {
          type: "ACTIVE",
        },
      },
    }
  );
  const { data: productPriceLists } = useQuery<GraphQLMeta<PriceList>>(
    Api.PriceLists.LIST_PRICE_LISTS,
    {
      fetchPolicy: "no-cache",
      variables: {
        filters: {
          productId,
          type: "ACTIVE",
        },
      },
    }
  );
  const [mutation, { loading }] = useMutation(
    edit ? Api.PriceLists.EDIT_ITEM_IN_LISTS : Api.PriceLists.ADD_ITEM_TO_LISTS,
    {
      onCompleted: () => {
        alert(
          edit
            ? "El producto ha sido modificado en las listas de precios"
            : "El producto ha sido agregado a las listas de precios"
        );

        if (edit) {
          if (location.state) {
            navigate(-1);
          } else {
            window.location.reload();
          }
        } else {
          navigate(`/products/${productId}`);
        }
      },
    }
  );

  useImperativeHandle(
    ref,
    () => ({
      open: (id: number) => {
        setProductId(id);
        setShowModal(true);
      },
      close: () => {
        setProductId(undefined);
        setShowModal(false);
      },
    }),
    []
  );

  const togglePriceList = (id: number) => {
    setPriceListIds((prev) => {
      if (prev.includes(id)) {
        return prev.filter((priceListId) => priceListId !== id);
      }

      return [...prev, id];
    });
  };

  const updatePriceLists = () => {
    if (!loading) {
      if (priceListIds.length === 0) {
        alert(
          "Debes seleccionar al menos una lista de precios para continuar."
        );
      } else {
        mutation({
          variables: {
            productId,
            priceListIds,
          },
        });
      }
    }
  };

  const { priceListsWithItem, priceListsWithoutItem }: FilteredPriceLists =
    useMemo(() => {
      const filteredPriceLists: FilteredPriceLists = {
        priceListsWithItem: [],
        priceListsWithoutItem: [],
      };

      if (!allPriceLists?.data?.data || !productPriceLists?.data?.data) {
        return filteredPriceLists;
      }

      const filteredProductPriceLists = productPriceLists.data.data.filter(
        (list) => (edit ? true : list.store.type !== StoreType.TiendaNube)
      );

      const filteredAllPriceLists = allPriceLists.data.data.filter((list) =>
        edit ? true : list.store.type !== StoreType.TiendaNube
      );

      const withItemId = filteredProductPriceLists.map((list) => list.id);

      if (withItemId.length === 0) {
        filteredPriceLists.priceListsWithoutItem = [...filteredAllPriceLists];

        return filteredPriceLists;
      }

      filteredAllPriceLists.forEach((list) => {
        if (withItemId.includes(list.id)) {
          filteredPriceLists.priceListsWithItem.push(list);
        } else if (list.store.type === StoreType.Store) {
          filteredPriceLists.priceListsWithoutItem.push(list);
        }
      });

      return filteredPriceLists;
    }, [allPriceLists?.data.data, edit, productPriceLists?.data.data]);

  useEffect(() => {
    if (edit && priceListsWithItem.length > 0) {
      setPriceListIds(priceListsWithItem.map((list) => list.id));
    }

    if (!edit && priceListsWithoutItem.length > 0) {
      setPriceListIds(priceListsWithoutItem.map((list) => list.id));
    }
  }, [edit, priceListsWithItem, priceListsWithoutItem]);

  const isDebtor =
    getCompanyPaymentStatus(currentCompany?.paymentStatus) ===
    PaymentStatus.DEBTOR;

  return (
    <CModal
      alignment="center"
      size="lg"
      backdrop="static"
      visible={showModal}
      onClose={() => setShowModal(false)}
      onShow={() => {
        setTimeout(() => {
          successButtonRef.current?.focus();
        }, 300);
      }}
    >
      <CModalHeader closeButton={false}>
        {edit ? "Actualización" : "Creación"} de Producto
      </CModalHeader>
      <CModalBody>
        El producto ha sido {edit ? "actualizado" : "creado"}.
        {(priceListsWithItem.length > 0 ||
          priceListsWithoutItem.length > 0) && (
          <>
            <FeatureAlert />

            {!isDebtor && priceListsWithItem.length > 0 && (
              <>
                {edit
                  ? "¿Quieres editar este producto en alguna de estas listas de precios?"
                  : "¿Quieres agregar este producto a alguna de estas listas de precios?"}
                <CRow className="mt-3">
                  <CCol md="12" className="mt-2 mb-3">
                    {priceListsWithItem.map((priceList) => {
                      const currentType = findPointOfSale(priceList.store.type);
                      const StoreIcon = () => currentType?.icon;
                      const isStoreDebtor =
                        getStorePaymentStatus(
                          priceList.store.id,
                          currentCompany?.paymentStatus
                        ) === PaymentStatus.DEBTOR;

                      return (
                        <CRow className="px-3" key={priceList.id}>
                          <CFormCheck
                            inline
                            id={`priceList-${priceList.id}`}
                            value={priceList.id}
                            checked={
                              isStoreDebtor
                                ? false
                                : priceListIds.includes(priceList.id)
                            }
                            disabled={isStoreDebtor}
                            label={
                              <div className="d-flex">
                                <StoreIcon />
                                <span className="ml-1">
                                  {priceList.store?.name} - {priceList?.name}
                                </span>
                              </div>
                            }
                            name={`priceList-${priceList.id}`}
                            onChange={() => togglePriceList(priceList.id)}
                          />
                        </CRow>
                      );
                    })}
                  </CCol>
                </CRow>
              </>
            )}
            {priceListsWithoutItem.length > 0 && (
              <>
                Este producto no se encontraba en las siguientes listas de
                precios, ¿Quieres agregarlo e incluirlo con los nuevos cambios
                en alguna de estas listas de precios?
                <CRow className="mt-3">
                  <CCol md="12" className="mt-2 mb-3">
                    {priceListsWithoutItem.map((priceList) => {
                      const currentType = findPointOfSale(priceList.store.type);
                      const StoreIcon = () => currentType?.icon;
                      const isStoreDebtor =
                        getStorePaymentStatus(
                          priceList.store.id,
                          currentCompany?.paymentStatus
                        ) === PaymentStatus.DEBTOR;

                      return (
                        <CRow className="px-3" key={priceList.id}>
                          <CFormCheck
                            inline
                            id={`priceList-${priceList.id}`}
                            value={priceList.id}
                            checked={
                              isStoreDebtor
                                ? false
                                : priceListIds.includes(priceList.id)
                            }
                            disabled={isStoreDebtor}
                            label={
                              <div className="d-flex">
                                <StoreIcon />
                                <span className="ml-1">
                                  {priceList.store?.name} - {priceList?.name}
                                </span>
                              </div>
                            }
                            name={`priceList-${priceList.id}`}
                            onChange={() => togglePriceList(priceList.id)}
                          />
                        </CRow>
                      );
                    })}
                  </CCol>
                </CRow>
              </>
            )}
          </>
        )}
      </CModalBody>
      <CModalFooter>
        <CButton
          size="sm"
          type="button"
          color="secondary"
          disabled={loading}
          onClick={() => {
            setShowModal(false);

            if (edit) {
              navigate(-1);
            } else {
              navigate(`/products/${productId}`, { state: { refetch: true } });
            }
          }}
        >
          {isDebtor ? "Cerrar" : "Cancelar"}
        </CButton>
        {!isDebtor && (
          <CButton
            size="sm"
            ref={successButtonRef}
            disabled={
              loading ||
              (priceListsWithItem.length > 0 && priceListIds.length === 0)
            }
            onClick={() => updatePriceLists()}
            color="success"
          >
            Continuar
          </CButton>
        )}
      </CModalFooter>
    </CModal>
  );
};

export default forwardRef(SuccessModal);
