import { useLocation, useNavigate } from "react-router-dom";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CSmartTable,
  CRow,
  CForm,
  CFormInput,
  CButton,
  CFormSelect,
  CLink,
  CBadge,
} from "@coreui/react-pro";

import { useQuery } from "@apollo/client";

import { parseOrderStatus, Order, statuses } from "src/api/orders";
import { dateFormat } from "src/helpers/dates";
import { formatCurrency } from "src/helpers/numbers";

import { useFormik } from "formik";
import { GraphQLMeta, SearchForm, WithDate } from "src/types";
import { usePagination } from "src/hooks/pagination";
import Api from "src/api";
import { useAdminStore } from "src/store";
import { queryStringToObject } from "src/helpers/strings";
import { useOrder } from "src/hooks/useOrder";
import { useCanNavigate } from "src/helpers/permissions";
import Pagination from "src/components/Pagination";
import { TextSearch } from "lucide-react";

const getCustomer = (order: Order) => {
  if (order.customer.alias) {
    return order.customer.alias;
  }

  if (order.customer.email) {
    return order.customer.email;
  }

  if (order.customer.name || order.customer.lastname) {
    return `${order.customer.name} ${order.customer.lastname}`.trim();
  }

  return "Cliente Anónimo";
};

const Orders = () => {
  const navigate = useNavigate();
  const { hasPermission } = useAdminStore();
  const querySearch = queryStringToObject(useLocation().search);
  const canSeeOrder = hasPermission("SHOW_ORDER");
  const { calculateSummary } = useOrder();

  useCanNavigate("LIST_ORDERS");

  const {
    data: orders,
    refetch,
    loading,
  } = useQuery<GraphQLMeta<Order>>(Api.Orders.LIST_ORDERS, {
    fetchPolicy: "no-cache",
    variables: {
      filters: {
        page: querySearch.page ? Number(querySearch.page) : 1,
        search: querySearch.search ?? "",
        date: querySearch.date ?? "",
        status: querySearch?.status ?? "ALL",
      },
    },
  });
  const { page, pageChange, resetAndSearch } = usePagination("orders", refetch);

  const formik = useFormik<WithDate<SearchForm>>({
    initialValues: {
      date: querySearch.date,
      search: querySearch.search ?? "",
      status: "ALL",
    },

    onSubmit: (input) => {
      if (!loading) {
        resetAndSearch({
          date: input.date ?? "",
          search: input.search ?? "",
          status: input.status,
          page: 1,
        });
      }
    },
  });

  return (
    <CRow>
      <CCol xl={12}>
        <CCard>
          <CCardHeader>
            <CRow className="align-items-center justify-content-center">
              <CCol sm={6}>Pedidos</CCol>
              <CCol sm={6} className="d-flex justify-content-end"></CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CForm onSubmit={formik.handleSubmit} className="mb-3">
              <CRow className="align-items-center justify-content-center">
                <CCol
                  sm={12}
                  className="row justify-content-end"
                  style={{ flexWrap: "nowrap" }}
                >
                  <CFormInput
                    placeholder="Buscar..."
                    name="search"
                    onChange={formik.handleChange}
                    style={{ flex: 1 }}
                  />
                  <CFormSelect
                    name="status"
                    onChange={formik.handleChange}
                    style={{ flex: 0.2, marginLeft: 12 }}
                  >
                    <option value="ALL">Todos</option>
                    {statuses.map((status, index) => (
                      <option key={index} value={status.value}>
                        {status.name}
                      </option>
                    ))}
                  </CFormSelect>
                  <CFormInput
                    name="date"
                    onChange={formik.handleChange}
                    defaultValue={formik.values.date}
                    type="date"
                    style={{ flex: 0.2, marginLeft: 12 }}
                  />
                  <CButton
                    type="submit"
                    color="primary"
                    className="ml-2 p-0 w-10 h-10"
                  >
                    <TextSearch />
                  </CButton>
                </CCol>
              </CRow>
            </CForm>

            <CSmartTable
              itemsPerPage={20}
              items={
                orders?.data.data.map((order) => ({
                  ...order,
                  summary: calculateSummary(order),
                  _props: { color: order.deletedAt ? "danger" : "default" },
                })) || []
              }
              columns={[
                { key: "id", label: "ID" },
                { key: "store", label: "Punto de Venta" },
                { key: "customer", label: "Cliente" },
                {
                  key: "subtotal",
                  label: "Subtotal",
                  _props: { className: "text-right" },
                },
                {
                  key: "tax",
                  label: "IVA",
                  _props: { className: "text-right" },
                },
                {
                  key: "total",
                  label: "Total",
                  _props: { className: "text-right" },
                },
                {
                  key: "status",
                  label: "Estado",
                  _props: { className: "text-center" },
                },
                {
                  key: "date",
                  label: "Fecha",
                  _props: { className: "font-weight-bold text-right" },
                },
              ]}
              loading={loading}
              scopedColumns={{
                store: (order: Order) => <td>{order.store.name}</td>,
                customer: (order: Order) => (
                  <td>
                    <CLink href={`/#/customers/${order.customer.id}`}>
                      {getCustomer(order)}
                    </CLink>
                  </td>
                ),
                subtotal: (
                  order: Order & { summary: { subtotal: number } }
                ) => {
                  return (
                    <td className="text-right">
                      {formatCurrency(order.summary.subtotal)}
                    </td>
                  );
                },
                tax: (order: Order & { summary: { tax: number } }) => {
                  return (
                    <td className="text-right">
                      {formatCurrency(order.summary.tax)}
                    </td>
                  );
                },
                total: (order: Order & { summary: { total: number } }) => {
                  return (
                    <td className="text-right">
                      {formatCurrency(order.summary.total)}
                    </td>
                  );
                },
                status: (order: Order) => {
                  const parsed = parseOrderStatus(order.status);

                  return (
                    <td className="text-center">
                      <CBadge color={parsed.color}>{parsed.label}</CBadge>
                    </td>
                  );
                },
                date: (order: Order) => (
                  <td className="text-right">
                    {dateFormat(order.date, "dd/MM/yyyy HH:mm")}
                  </td>
                ),
              }}
              tableProps={{
                striped: true,
                hover: true,
              }}
              clickableRows={canSeeOrder}
              onRowClick={(order) => {
                if (canSeeOrder) {
                  navigate(`/orders/${order.id}`);
                }
              }}
            />

            <Pagination meta={orders} page={page} pageChange={pageChange} />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default Orders;
