import { useQuery } from "@apollo/client";
import { CSmartTable } from "@coreui/react-pro";
import { FileText } from "lucide-react";
import { useNavigate } from "react-router-dom";
import Api from "src/api";
import { PaymentMethod, Sale } from "src/api/sales";

import { AppLoader } from "src/components/Loader/Loader";
import { dateFormat } from "src/helpers/dates";
import { formatCurrency } from "src/helpers/numbers";
import { parsePaymentMethods } from "src/helpers/payments";
import { useCanNavigate } from "src/helpers/permissions";
import { getPaginationProps } from "src/hooks/pagination";
import { useAdminStore } from "src/store";
import { GraphQLMeta } from "src/types";

const CustomerSales = ({ customer }) => {
  const { hasPermission } = useAdminStore();

  const canSeeSale = hasPermission("SHOW_SALE");
  const navigate = useNavigate();

  useCanNavigate("LIST_SALES");

  const { data: sales, loading } = useQuery<GraphQLMeta<Sale>>(
    Api.Sales.LIST_SALES,
    {
      variables: {
        filters: {
          limit: 0,
          customerId: customer.id,
        },
      },
    }
  );

  if (!sales?.data.data) {
    return <AppLoader />;
  }

  return (
    <CSmartTable
      itemsPerPage={20}
      items={sales.data.data ?? []}
      {...getPaginationProps(sales.data.data)}
      columns={[
        { key: "id", label: "ID" },
        {
          key: "store",
          label: "Punto de Venta",
          _props: { className: "font-weight-bold" },
        },
        { key: "user", label: "Vendedor" },
        { key: "payments", label: "Métodos de Pago" },
        {
          key: "amount",
          label: "Monto",
          _props: { className: "text-right" },
        },
        {
          key: "date",
          label: "Fecha",
          _props: { className: "font-weight-bold text-right" },
        },
        {
          key: "file",
          label: "",
          _props: { className: "text-right" },
        },
      ]}
      loading={loading}
      scopedColumns={{
        store: (sale: Sale) => <td>{sale.store.name}</td>,
        user: (sale: Sale) => (
          <td>
            {sale.user?.name} {sale.user?.lastname}
          </td>
        ),
        payments: (sale: Sale) => <td>{parsePaymentMethods(sale.payments)}</td>,
        amount: (sale: Sale) => (
          <td className="text-right">
            {formatCurrency(
              sale.payments
                .filter(
                  (payment) =>
                    ![
                      PaymentMethod.Discount,
                      PaymentMethod.CustomerDiscount,
                    ].includes(payment.type)
                )
                .reduce((prev, curr) => prev + curr.amount, 0)
            )}
          </td>
        ),
        date: (sale: Sale) => (
          <td className="text-right">
            {dateFormat(sale.date, "dd/MM/yyyy HH:mm")}
          </td>
        ),
        file: (sale: Sale) => (
          <td className="text-right">
            {sale.invoices?.length > 0 && <FileText color="black" />}
          </td>
        ),
      }}
      tableProps={{
        striped: true,
        hover: canSeeSale,
      }}
      clickableRows={canSeeSale}
      onRowClick={(sale) => {
        if (canSeeSale) {
          navigate(`/sales/${sale.id}`);
        }
      }}
    />
  );
};

export default CustomerSales;
