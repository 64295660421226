import {
  CCol,
  CButton,
  CCard,
  CCardBody,
  CRow,
  CSmartTable,
  CCardHeader,
} from "@coreui/react-pro";
import { CashRegister } from "src/api/registers";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import Api from "src/api";
import { GraphQLMeta } from "src/types";
import { usePagination } from "src/hooks/pagination";
import { dateFormat } from "src/helpers/dates";
import { useAdminStore } from "src/store";
import PlanAlert from "src/containers/PlanAlert";
import Pagination from "src/components/Pagination";
import { findPointOfSale } from "src/helpers/stores";

const RegistersScreen = () => {
  const { hasPermission } = useAdminStore();
  const canSeeRegister = hasPermission("SHOW_REGISTER");
  const navigate = useNavigate();
  const { page, pageChange } = usePagination("registers");

  const { data: registers, loading } = useQuery<GraphQLMeta<CashRegister>>(
    Api.Registers.LIST_REGISTERS,
    {
      variables: {
        filters: {
          page,
          plain: true,
        },
      },
    }
  );

  return (
    <>
      <PlanAlert />
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader>
              <CRow className="align-items-center justify-content-center">
                <CCol sm={3}>Cajas Registradoras</CCol>
                <CCol sm={9} className="d-flex justify-content-end">
                  {hasPermission("CREATE_REGISTER") && (
                    <>
                      <Link to="/registers/methods" className="mr-2">
                        <CButton size="sm" color="primary">
                          Métodos de Ingreso / Egreso
                        </CButton>
                      </Link>

                      <Link to="/registers/new">
                        <CButton size="sm" color="info">
                          Crear caja registradora
                        </CButton>
                      </Link>
                    </>
                  )}
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CSmartTable
                itemsPerPage={20}
                loading={loading}
                items={registers?.data.data || []}
                columns={[
                  { key: "id", label: "ID" },
                  { key: "store", label: "Punto de Venta" },
                  {
                    key: "name",
                    label: "Nombre",
                  },
                  {
                    key: "status",
                    label: "Estado",
                  },
                  {
                    key: "openedBy",
                    label: "Abierta por",
                  },
                  {
                    key: "openedAt",
                    label: "Abierta a las",
                  },
                  {
                    key: "closedAt",
                    label: "Cerrada a las",
                  },
                ]}
                scopedColumns={{
                  store: (item: CashRegister) => {
                    const currentType = findPointOfSale(item.store.type);
                    const StoreIcon = () => currentType?.icon;

                    return (
                      <td>
                        <div className="d-flex align-items-center gap-2">
                          <StoreIcon />
                          {item.store.name}
                        </div>
                      </td>
                    );
                  },
                  status: (item: CashRegister) => (
                    <td>
                      <span>{item.session ? "Abierta" : "Cerrada"}</span>
                    </td>
                  ),
                  openedBy: (item: CashRegister) => (
                    <td>
                      <span>
                        {item.session
                          ? `${item.session.user.name} ${item.session.user.lastname}`
                          : " - "}
                      </span>
                    </td>
                  ),
                  openedAt: (item: CashRegister) => (
                    <td>
                      <span>
                        {item.session
                          ? dateFormat(
                              new Date(item.session?.openedAt),
                              "dd/MM/yyyy HH:mm"
                            )
                          : " - "}
                      </span>
                    </td>
                  ),
                  closedAt: (item: CashRegister) => (
                    <td>
                      <span>
                        {item.session?.closedAt
                          ? dateFormat(
                              new Date(item.session.closedAt),
                              "dd/MM/yyyy HH:mm"
                            )
                          : " - "}
                      </span>
                    </td>
                  ),
                }}
                tableProps={{
                  striped: true,
                  hover: true,
                }}
                clickableRows={canSeeRegister}
                onRowClick={(item) => {
                  if (canSeeRegister) {
                    navigate(`/registers/${item.id}`);
                  }
                }}
              />

              <Pagination
                meta={registers}
                page={page}
                pageChange={pageChange}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default RegistersScreen;
