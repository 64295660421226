import { CImage, CForm, CRow, CCol, CFormLabel } from "@coreui/react-pro";
import { useRef, useState } from "react";
import Api from "src/api";
import { Company } from "src/api/companies";
import { useAdminStore } from "src/store";

const BrandingSettings = ({
  company,
  refetch,
  isFranchise,
}: {
  company: Company;
  isFranchise: boolean;
  refetch: () => void;
}) => {
  const { hasPermission } = useAdminStore();
  const imageRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const canEdit = hasPermission("UPDATE_COMPANY") && !isFranchise;
  const canEditWithFranchise = hasPermission("UPDATE_COMPANY") && isFranchise;

  const importImage = async () => {
    if (imageRef.current?.files?.length && !loading) {
      const image = imageRef.current.files[0];

      setLoading(true);

      const formData = new FormData();

      formData.append("file", image);

      try {
        await Api.Companies.uploadLogo(company.id, formData);

        refetch();
      } catch (e) {
        //
      } finally {
        if (imageRef.current) {
          imageRef.current.value = "";
        }

        setLoading(false);
      }
    }
  };

  return (
    <CForm encType="multipart/form-data">
      <CRow>
        <CCol sm={2}>
          <CFormLabel>Logo de Empresa</CFormLabel>
          <CImage
            thumbnail
            src={company.logoUrl ?? "https://picsum.photos/200/200"}
            width={156}
            height={156}
            onClick={() => {
              if (canEdit || canEditWithFranchise) {
                imageRef.current?.click();
              }
            }}
          />
        </CCol>
      </CRow>

      <input
        type="file"
        style={{ display: "none" }}
        ref={imageRef}
        onChange={importImage}
        accept=".jpeg,.jpg,.png"
      />
    </CForm>
  );
};

export default BrandingSettings;
