import { useLazyQuery } from "@apollo/client";
import { CCol, CFormSelect, CLoadingButton, CRow } from "@coreui/react-pro";
import { useState } from "react";
import Api from "src/api";
import { Category } from "src/api/categories";
import { Product } from "src/api/products";
import { PaymentMethod } from "src/api/sales";
import RangeAndStorePicker from "src/components/RangeAndStorePicker";
import { dateFormat } from "src/helpers/dates";
import { formatCurrency } from "src/helpers/numbers";
import { findPaymentMethod, paymentMethods } from "src/helpers/payments";
import { truncateString } from "src/helpers/strings";
import writeXlsxFile from "write-excel-file";

type PickedCategory = Pick<Category, "id" | "name">;
type SummedItems = {
  product: Pick<Product, "id" | "name" | "suppliers">;
  quantity: number;
  price: number;
  cost: number;
};

type FilterValues = {
  storeId?: number;
  storeName?: string;
  dateFrom: string;
  dateTo: string;
  paymentMethod?: PaymentMethod;
};

const SalesReport = () => {
  const [filterValues, setFilterValues] = useState<FilterValues>({
    dateFrom: dateFormat(new Date(), "yyyy-MM-dd"),
    dateTo: dateFormat(new Date(), "yyyy-MM-dd"),
    paymentMethod: undefined,
  });
  const [fetchReport, { loading }] = useLazyQuery<{
    data: { category: PickedCategory; items: SummedItems[] }[];
  }>(Api.Excels.SELLS_BY_CATEGORY_AND_DATE, {
    onCompleted: ({ data }) => {
      const excelData: any[][] = [[]];
      const consolidatedSheet: any[] = [];

      const consolidated = data.flatMap(({ items }) => items);

      consolidatedSheet.push([
        { value: "Producto" },
        { value: "Proveedor(es)" },
        { value: "Cantidad" },
        { value: "Costo Total" },
        { value: "Importe Total" },
      ]);

      consolidated.forEach((item) => {
        consolidatedSheet.push([
          {
            value: item.product.name,
          },
          {
            value: item.product.suppliers
              .map((s) => s.supplier.name)
              .join("; "),
          },
          {
            value: item.quantity,
          },
          {
            value: item.cost,
          },
          {
            value: item.price,
          },
        ]);
      });

      const { tQuantity, tCost, tPrice } = consolidated.reduce(
        (prev, curr) => {
          return {
            ...prev,
            tQuantity: prev.tQuantity + curr.quantity,
            tCost: prev.tCost + curr.cost,
            tPrice: prev.tPrice + curr.price,
          };
        },
        {
          tQuantity: 0,
          tCost: 0,
          tPrice: 0,
        }
      );

      consolidatedSheet.push([
        {
          value: "",
        },
        {
          value: "",
        },
        {
          value: tQuantity,
        },
        {
          value: formatCurrency(tCost),
        },
        {
          value: formatCurrency(tPrice),
        },
      ]);

      data.forEach(({ items }, index) => {
        const excelSheet: any[] = [];

        excelSheet.push([
          { value: "Producto" },
          { value: "Proveedor(es)" },
          { value: "Cantidad" },
          { value: "Costo Total" },
          { value: "Importe Total" },
        ]);

        if (items.length === 0) {
          excelData[index] = excelSheet;

          return;
        }

        items.forEach((item) => {
          excelSheet.push([
            {
              value: item.product.name,
            },
            {
              value: item.product.suppliers
                .map((s) => s.supplier.name)
                .join("; "),
            },
            {
              value: item.quantity,
            },
            {
              value: item.cost,
            },
            {
              value: item.price,
            },
          ]);
        });

        const { quantity, cost, price } = items.reduce(
          (prev, curr) => {
            return {
              ...prev,
              quantity: prev.quantity + curr.quantity,
              cost: prev.cost + curr.cost,
              price: prev.price + curr.price,
            };
          },
          {
            quantity: 0,
            cost: 0,
            price: 0,
          }
        );

        excelSheet.push([
          {
            value: "",
          },
          {
            value: "",
          },
          {
            value: quantity,
          },
          {
            value: formatCurrency(cost),
          },
          {
            value: formatCurrency(price),
          },
        ]);

        excelData[index] = excelSheet;
      });

      const dateFrom = dateFormat(filterValues.dateFrom, "dd/MM/yyyy", {
        add: { hours: 3 },
      });
      const dateTo = dateFormat(filterValues.dateTo, "dd/MM/yyyy", {
        add: { hours: 3 },
      });

      const reportName = ["Reporte de egresos"];

      if (dateFrom === dateTo) {
        reportName.push(` del ${dateFrom}`);
      } else {
        reportName.push(` desde ${dateFrom} hasta ${dateTo}`);
      }

      reportName.push(` del punto de venta ${filterValues.storeName}`);

      if (filterValues.paymentMethod) {
        reportName.push(
          ` con método de pago ${
            findPaymentMethod(filterValues.paymentMethod)?.name
          }`
        );
      }

      writeXlsxFile([consolidatedSheet, ...excelData], {
        sheets: [
          `Consolidado (${consolidated.length})`,
          ...data.map(
            ({ category, items }) =>
              `${truncateString(category.name.replaceAll("/", "-"), 20)} (${
                items.length
              })`
          ),
        ],
        fileName: `${reportName.join("")}.xlsx`,
        stickyRowsCount: 1,
      });
    },
    fetchPolicy: "no-cache",
  });

  return (
    <>
      {" "}
      <RangeAndStorePicker
        refetch={({ variables }: { variables: FilterValues }) => {
          setFilterValues(variables);
        }}
        defaultLabel="Seleccione Punto de Venta"
        eventTitle="excels.sell-products.filter"
      />
      <CRow className="mt-3">
        <CCol sm={3}>
          <CFormSelect
            floatingLabel="Método de Pago"
            name="paymentMethod"
            onChange={(e) => {
              const paymentMethod = e.target.value;

              setFilterValues((prev) => ({
                ...prev,
                paymentMethod:
                  paymentMethod !== ""
                    ? (paymentMethod as PaymentMethod)
                    : undefined,
              }));
            }}
          >
            <option value="">Todos</option>
            {paymentMethods
              ?.filter(({ id, digital }) => !digital && id > 0)
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((paymentMethod) => (
                <option value={paymentMethod.type} key={paymentMethod.id}>
                  {paymentMethod.name}
                </option>
              ))}
          </CFormSelect>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm={2}></CCol>
        <CCol className="text-right" sm={10}>
          <CLoadingButton
            color="primary"
            onClick={() => {
              fetchReport({
                variables: {
                  filters: {
                    dateFrom: filterValues.dateFrom,
                    dateTo: filterValues.dateTo,
                    storeId: filterValues.storeId,
                    paymentMethod: filterValues.paymentMethod,
                  },
                },
              });
            }}
            loading={loading}
            size="sm"
            disabled={filterValues.storeId === undefined}
          >
            Descargar Reporte
          </CLoadingButton>
        </CCol>
      </CRow>
    </>
  );
};

export default SalesReport;
