import { useMutation, useQuery } from "@apollo/client";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CLoadingButton,
  CRow,
  CSmartTable,
} from "@coreui/react-pro";
import { Item } from "@coreui/react-pro/dist/esm/components/smart-table/types";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import Api from "src/api";
import { AppLoader } from "src/components/Loader/Loader";
import { dateFormat } from "src/helpers/dates";
import { usePagination } from "src/hooks/pagination";
import { useAdminStore } from "src/store";
import { GraphQLMeta } from "src/types";
import yup from "src/helpers/validation";
import { Retire } from "src/api/retires";
import MoveList from "./components/MoveList";
import Pagination from "src/components/Pagination";
import { queryStringToObject } from "src/helpers/strings";

export const MoveStockSchema = yup.object().shape({
  productId: yup.number().min(1).required(),
  cost: yup.number().min(0.1).required(),
  quantity: yup.number().min(0.1).required(),
});

const InitialScreen = () => {
  const { state, search } = useLocation();
  const { hasPermission, currentCompany, user } = useAdminStore();
  const [currentRetireId, setCurrentRetireId] = useState<number>();
  const querySearch = { ...queryStringToObject(search), ...state };

  const { data: retires, refetch } = useQuery<GraphQLMeta<Retire>>(
    Api.Retires.LIST_RETIRES,
    {
      fetchPolicy: "no-cache",
      variables: {
        filters: {
          page: querySearch.page ? Number(querySearch.page) : 1,
          companyId: currentCompany?.id,
          warehouseId: querySearch.warehouseId,

          type: ["MOVE"],
        },
      },
    }
  );
  const [createRetire, { loading }] = useMutation(Api.Retires.CREATE_RETIRE, {
    onCompleted: ({ data }) => {
      setCurrentRetireId(data.id);
      refetch();
    },
  });

  const { page, pageChange } = usePagination(`stock/move`, refetch);

  if (!retires?.data.data) {
    return <AppLoader />;
  }

  const retiresList = retires.data.data;

  if (currentRetireId) {
    return (
      <MoveList
        retireId={currentRetireId}
        warehouseId={state.warehouseId}
        goBack={() => {
          setCurrentRetireId(undefined);
          refetch();
        }}
      />
    );
  }

  let canCreateMove = false;

  if (state?.storeIds?.length > 0) {
    canCreateMove = state?.storeIds?.some(
      (storeId: number) =>
        hasPermission("CREATE_BATCH", { type: "store", id: storeId }) &&
        hasPermission("CREATE_RETIRE", { type: "store", id: storeId })
    );
  } else {
    canCreateMove =
      hasPermission("CREATE_BATCH", {
        type: "company",
        id: state?.companyId,
      }) &&
      hasPermission("CREATE_RETIRE", { type: "company", id: state?.companyId });
  }

  return (
    <CCard>
      <CCardHeader>
        <CRow className="align-items-center justify-content-center">
          <CCol sm={6}>Movimientos de {state.warehouseName}</CCol>
          <CCol sm={6} className="text-right">
            {canCreateMove && (
              <CLoadingButton
                color="primary"
                loading={loading}
                disabled={loading}
                size="sm"
                onClick={() => {
                  createRetire({
                    variables: {
                      input: {
                        companyId: currentCompany?.id,
                        userId: user?.id,
                        warehouseId: state?.warehouseId,
                        type: "MOVE",
                      },
                    },
                  });
                }}
              >
                Crear nuevo movimiento
              </CLoadingButton>
            )}
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <CSmartTable
          items={retiresList ?? []}
          noItemsLabel="Aun no has hecho movimientos de stock entre depósitos"
          columns={[
            { key: "id", label: "ID" },
            { key: "user", label: "Usuario" },
            { key: "warehouse", label: "Depósito Salida" },
            { key: "toWarehouse", label: "Depósito Entrada" },
            {
              key: "createdAt",
              label: "Fecha de Creación",
              _props: { className: "text-center" },
            },
            {
              key: "updatedAt",
              label: "Ultima actualización",
              _props: { className: "text-center" },
            },
            {
              key: "status",
              label: "Estado",
              _props: { className: "text-right" },
            },
          ]}
          scopedColumns={{
            user: (item: Retire) => (
              <td>
                {item.user.name} {item.user.lastname}
              </td>
            ),
            warehouse: (item: Retire) => <td>{item.warehouse.name}</td>,
            toWarehouse: (item: Retire) => <td>{item.toWarehouse?.name}</td>,
            createdAt: (item: Retire) => (
              <td className="text-center">
                {dateFormat(item.createdAt, "dd/MM/yyyy HH:mm")}
              </td>
            ),
            updatedAt: (item: Retire) => (
              <td className="text-center">
                {dateFormat(item.updatedAt, "dd/MM/yyyy HH:mm")}
              </td>
            ),
            status: (item: Retire) => (
              <td className="text-right">
                {item.status === "OPEN" && "Abierto"}
                {item.status === "FULFILLED" && "Movido"}
                {item.status === "CLOSED" && "Cerrado"}
              </td>
            ),
          }}
          tableProps={{
            striped: true,
          }}
          clickableRows
          onRowClick={(item: Item) => {
            setCurrentRetireId(item.id);
          }}
        />

        <Pagination meta={retires} page={page} pageChange={pageChange} />
      </CCardBody>
    </CCard>
  );
};

export default InitialScreen;
