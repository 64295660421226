import { useEffect } from "react";
import {
  CCol,
  CButton,
  CForm,
  CSmartTable,
  CRow,
  CFormInput,
} from "@coreui/react-pro";
import { useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import Api from "src/api";
import { usePagination } from "src/hooks/pagination";
import { queryStringToObject } from "src/helpers/strings";
import { useLocation } from "react-router-dom";
import { GraphQLMeta, SearchForm } from "src/types";
import { PriceListItem } from "src/api/price-lists";
import { StoreType } from "src/api/stores";
import Pagination from "src/components/Pagination";
import { ArrowDownFromLine, LoaderCircle, TextSearch } from "lucide-react";

const NonEditablePriceListItems = ({ priceListId, type }) => {
  const querySearch = queryStringToObject(useLocation().search);

  const {
    data: items,
    refetch,
    loading,
  } = useQuery<GraphQLMeta<PriceListItem>>(
    Api.PriceLists.LIST_PRICE_LIST_ITEMS,
    {
      variables: {
        fetchPolicy: "no-cache",
        filters: {
          page: querySearch.page ? Number(querySearch.page) : 1,
          priceListId,
          search: querySearch.search ?? "",
        },
      },
    }
  );
  const { page, pageChange, resetAndSearch } = usePagination(
    `price-lists/${priceListId}`,
    refetch,
    { priceListId }
  );

  const [tiendaNubeDownload, { loading: tiendaNubeLoading }] = useMutation(
    Api.TiendaNube.DOWNLOAD_PRODUCTS,
    {
      onCompleted: () => {
        window.location.reload();
      },
    }
  );

  const [mercadoLibreDownload, { loading: mercadoLibreLoading }] = useMutation(
    Api.MercadoLibre.DOWNLOAD_MP_PRODUCTS,
    {
      onCompleted: () => {
        window.location.reload();
      },
    }
  );

  const [mercadoShopsDownload, { loading: mercadoShopsLoading }] = useMutation(
    Api.MercadoShops.DOWNLOAD_MS_PRODUCTS,
    {
      onCompleted: () => {
        window.location.reload();
      },
    }
  );

  const [rappiDownload, { loading: rappiLoading }] = useMutation(
    Api.Rappi.DOWNLOAD_RAPPI_PRODUCTS,
    {
      onCompleted: () => {
        window.location.reload();
      },
    }
  );

  const formik = useFormik<SearchForm & { priceListId: number }>({
    initialValues: {
      search: querySearch.search ?? "",
      priceListId,
    },
    onSubmit: (data) => {
      if (!loading) {
        resetAndSearch({
          search: data.search,
        });
      }
    },
  });

  useEffect(() => {
    resetAndSearch({
      search: formik.values.search,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CForm onSubmit={formik.handleSubmit}>
      <CRow>
        <CCol sm={12} className="d-flex align-items-center">
          <CFormInput
            placeholder="Buscar..."
            name="search"
            autoFocus
            onChange={formik.handleChange}
            defaultValue={formik.values.search}
            style={{ flex: 1 }}
          />
          <CButton type="submit" color="primary" className="ml-2 p-0 w-10 h-10">
            <TextSearch />
          </CButton>
          <CButton
            type="button"
            color="info"
            disabled={
              tiendaNubeLoading || mercadoLibreLoading || mercadoShopsLoading
            }
            className="ml-2 p-0 w-10 h-10"
            onClick={() => {
              let downloadMutation = (_: any) => {};

              if (type === StoreType.TiendaNube) {
                downloadMutation = tiendaNubeDownload;
              }

              if (type === StoreType.MercadoLibre) {
                downloadMutation = mercadoLibreDownload;
              }

              if (type === StoreType.MercadoShops) {
                downloadMutation = mercadoShopsDownload;
              }

              if (type === StoreType.Rappi) {
                downloadMutation = rappiDownload;
              }

              downloadMutation({
                variables: {
                  priceListId,
                },
              });
            }}
          >
            {tiendaNubeLoading ||
            rappiLoading ||
            mercadoLibreLoading ||
            mercadoShopsLoading ? (
              <LoaderCircle color="white" />
            ) : (
              <ArrowDownFromLine />
            )}
          </CButton>
        </CCol>
      </CRow>

      <CSmartTable
        itemsPerPage={items?.data.meta?.perPage ?? 10}
        className="mt-3"
        items={items?.data.data ?? []}
        columns={[
          {
            key: "productId",
            label: "ID",
          },
          {
            key: "sku",
            label: "SKU",
          },
          {
            key: "name",
            label: "Nombre",
          },
          {
            key: "url",
            label: "Acciones",
          },
        ]}
        scopedColumns={{
          productId: (item: PriceListItem) => (
            <td>
              <span>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`/#/products/${item.productId}`}
                >
                  {item.productId}
                </a>
              </span>
            </td>
          ),
          name: (item: PriceListItem) => (
            <td>
              <span>{item.name}</span>
            </td>
          ),
          sku: (item: PriceListItem) => (
            <td>
              <span>{item.sku ?? "-"}</span>
            </td>
          ),
          url: (item: PriceListItem) => (
            <td>
              <span>
                {type === StoreType.TiendaNube && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={item.metadata?.tiendaNubeUrl}
                  >
                    Ver en Tienda Nube
                  </a>
                )}

                {type === StoreType.MercadoLibre && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={item.metadata?.mercadoLibreUrl}
                  >
                    Ver en Mercado Libre
                  </a>
                )}

                {type === StoreType.MercadoShops && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={item.metadata?.mercadoShopsUrl}
                  >
                    Ver en Mercado Shops
                  </a>
                )}
              </span>
            </td>
          ),
        }}
        tableProps={{
          striped: true,
        }}
      />

      <Pagination meta={items} page={page} pageChange={pageChange} />
    </CForm>
  );
};
export default NonEditablePriceListItems;
