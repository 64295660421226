import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CSmartTable,
  CRow,
  CForm,
  CFormInput,
  CButton,
  CBadge,
} from "@coreui/react-pro";

import { useQuery } from "@apollo/client";

import { dateFormat } from "src/helpers/dates";
import { formatCurrency } from "src/helpers/numbers";

import { useFormik } from "formik";
import { usePagination } from "src/hooks/pagination";
import { GraphQLMeta, SearchForm } from "src/types";
import Api from "src/api";
import { queryStringToObject } from "src/helpers/strings";
import PlanAlert from "src/containers/PlanAlert";
import { MercadoLibreSale } from "src/api/mercado-libre";
import { parseSaleStatus } from "src/helpers/mercadoLibre";
import Pagination from "src/components/Pagination";
import { TextSearch } from "lucide-react";

const MercadoLibreSales = () => {
  const navigate = useNavigate();
  const querySearch = queryStringToObject(useLocation().search);
  const params = useParams();
  const storeId = Number(params.storeId);

  if (!storeId) {
    navigate("/mercado-libre");
  }

  const {
    data: sales,
    refetch,
    loading,
  } = useQuery<GraphQLMeta<MercadoLibreSale>>(Api.MercadoLibre.LIST_ML_SALES, {
    variables: {
      filters: {
        storeId,
        page: querySearch.page ? Number(querySearch.page) : 1,
        search: querySearch.search ?? "",
      },
    },
  });
  const { page, pageChange, resetAndSearch } = usePagination(
    `mercado-libre/${storeId}/sales`,
    refetch
  );

  const formik = useFormik<SearchForm>({
    initialValues: {
      search: querySearch.search ?? "",
    },
    onSubmit: (input) => {
      if (!loading) {
        resetAndSearch({
          search: input.search ?? "",
          page: 1,
        });
      }
    },
  });

  return (
    <>
      <PlanAlert />
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader>
              <CRow className="align-items-center justify-content-center">
                <CCol sm="6" xs="6" className="px-0">
                  Ventas
                </CCol>
                <CCol sm="6" xs="6" className="row justify-content-end"></CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CForm onSubmit={formik.handleSubmit} className="mb-3">
                <CRow className="align-items-center justify-content-center">
                  <CCol
                    sm={12}
                    className="row justify-content-end"
                    style={{ flexWrap: "nowrap" }}
                  >
                    <CFormInput
                      placeholder="Buscar por ID o Cliente"
                      name="search"
                      autoComplete="false"
                      defaultValue={formik.values.search}
                      onChange={formik.handleChange}
                      style={{ flex: 1 }}
                    />
                    <CButton
                      type="submit"
                      color="primary"
                      className="ml-2 p-0 w-10 h-10"
                    >
                      <TextSearch />
                    </CButton>
                  </CCol>
                </CRow>
              </CForm>

              <CSmartTable
                itemsPerPage={20}
                items={
                  sales?.data.data.map((sale) => ({
                    ...sale,
                  })) || []
                }
                columns={[
                  { key: "id", label: "ID" },
                  { key: "customer", label: "Usuario" },
                  { key: "status", label: "Estado" },
                  {
                    key: "amount",
                    label: "Monto",
                    _props: { className: "text-right" },
                  },
                  {
                    key: "date",
                    label: "Fecha",
                    _props: { className: "font-weight-bold text-right" },
                  },
                ]}
                loading={loading}
                scopedColumns={{
                  amount: (sale: MercadoLibreSale) => (
                    <td className="text-right">
                      {formatCurrency(sale.amounts.total)}
                    </td>
                  ),
                  customer: (sale: MercadoLibreSale) => (
                    <td>{sale.customer.username}</td>
                  ),
                  status: (sale: MercadoLibreSale) => {
                    const parsed = parseSaleStatus(sale.status);

                    return (
                      <td>
                        <CBadge
                          style={{ userSelect: "none", cursor: "pointer" }}
                          color={parsed.color}
                        >
                          {parsed.label}
                        </CBadge>
                      </td>
                    );
                  },
                  date: (sale: MercadoLibreSale) => (
                    <td className="text-right">
                      {dateFormat(sale.closedAt, "dd/MM/yyyy HH:mm")}
                    </td>
                  ),
                }}
                clickableRows
                onRowClick={(sale) => {
                  navigate(`/mercado-libre/${storeId}/sales/${sale.id}`);
                }}
                tableProps={{
                  striped: true,
                  hover: true,
                }}
              />

              <Pagination meta={sales} page={page} pageChange={pageChange} />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default MercadoLibreSales;
