import { useEffect, useRef, useState } from "react";
import {
  CCol,
  CButton,
  CCardFooter,
  CForm,
  CRow,
  CFormInput,
  CFormLabel,
  CAlert,
  CModal,
  CModalHeader,
  CModalFooter,
  CTabPane,
  CTabContent,
  CNavItem,
  CNavLink,
  CNav,
  CSmartTable,
  CCard,
  CCardHeader,
  CCardBody,
  CFormSwitch,
  CLoadingButton,
  CFormSelect,
  CInputGroup,
  CInputGroupText,
  CImage,
} from "@coreui/react-pro";
import { useMutation, useQuery } from "@apollo/client";
import { Store, StoreSchema, StoreType, StoreUpdate } from "src/api/stores";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { PriceList } from "src/api/price-lists";
import { CashRegister } from "src/api/registers";
import { useFormik } from "formik";
import { GraphQLFind, GraphQLMeta } from "src/types";
import Api from "src/api";
import { getValidity } from "src/helpers/validation";
import { dateFormat } from "src/helpers/dates";
import { AppLoader } from "src/components/Loader/Loader";
import { logEvent } from "src/helpers/analytics";
import { useAdminStore } from "src/store";
import ConnectModal, { ConnectModalProps } from "./components/ConnectModal";
import { generateConnectUrl } from "src/helpers/mercadoLibre";
import { Sector } from "src/api/sectors";
import { findPointOfSale } from "src/helpers/stores";
import ConnectionRow from "./components/ConnectionRow";
import ConnectRappiModal, {
  ConnectRappiModalProps,
} from "./components/ConnectRappiModal";

const TabIndex = ["#basic", "#registers", "#price-lists"];

const EditStore = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { hash, pathname, state } = location;
  const params = useParams();
  const storeId = Number(params.id);

  const initialTab = TabIndex.find((index) => index === hash)
    ? TabIndex.findIndex((index) => index === hash)
    : 0;

  if (!storeId) {
    navigate("/");
  }

  const [currentTab, setCurrentTab] = useState<number>(initialTab);
  const { data: store, refetch } = useQuery<GraphQLFind<Store>>(
    Api.Stores.GET_STORE,
    {
      fetchPolicy: "no-cache",
      variables: {
        id: storeId,
      },
    }
  );

  const { data: sectors } = useQuery<GraphQLMeta<Sector>>(
    Api.Sectors.LIST_SECTORS,
    {
      fetchPolicy: "no-cache",
    }
  );

  const onTabChange = (newIndex: number) => {
    navigate(`${pathname}${TabIndex[newIndex]}`);
    setCurrentTab(newIndex);
  };

  useEffect(() => {
    if (state?.refetch) {
      refetch({
        id: storeId,
      });
    }
  }, [state, refetch, storeId]);

  if (!store?.data || !sectors?.data) {
    return <AppLoader />;
  }

  return (
    <CCol lg={12}>
      <Form
        refetch={refetch}
        store={store.data}
        sectors={sectors.data.data}
        currentTab={currentTab}
        onTabChange={onTabChange}
      />
    </CCol>
  );
};

const Form = ({
  store,
  sectors,
  currentTab,
  onTabChange,
  refetch,
}: {
  store: Store;
  sectors: Sector[];
  currentTab: number;
  onTabChange: (index: number) => void;
  refetch: () => void;
}) => {
  const connectModalRef = useRef<ConnectModalProps>(null);
  const connectRappiModalRef = useRef<ConnectRappiModalProps>(null);
  const navigate = useNavigate();
  const imageRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const { hasPermission, user } = useAdminStore();
  const canEditStore = hasPermission("UPDATE_STORE");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [updateMutation, { error: updError, loading: updLoading }] =
    useMutation(Api.Stores.UPDATE_STORE, {
      onCompleted: () => window.location.reload(),
    });
  const [deleteMutation, { error: delError, loading: delLoading }] =
    useMutation(Api.Stores.DELETE_STORE, {
      onCompleted: () => {
        if (store) {
          navigate(`/companies/${store.companyId}#stores`, {
            state: { refetch: true, companyId: store.companyId },
          });
        }
      },
    });
  const [disconnectMutation, { loading: disLoading }] = useMutation(
    Api.TiendaNube.DISCONNECT_STORE,
    {
      onCompleted: () => {
        window.location.reload();
      },
    }
  );

  const onDelete = () => {
    if (!delLoading && !updLoading) {
      deleteMutation({
        variables: {
          id: store.id,
        },
      });
    }
  };

  const formik = useFormik<StoreUpdate>({
    initialValues: {
      companyId: store.companyId,
      sectorId: store.sector?.id ?? 0,
      name: store.name,
      streetName: store.streetName,
      streetNumber: store.streetNumber,
      phone: store.phone,
      utid: store.company.isFranchise ? store.utid : store.company.utid,
      subdomain: store.subdomain,
      website: store.website,
    },
    onSubmit: (input) => {
      if (!delLoading && !updLoading) {
        const data: Partial<StoreUpdate> = {
          name: input.name,
          streetName: input.streetName,
          streetNumber: input.streetNumber,
          phone: input.phone,
          utid: input.utid,
          subdomain: input.subdomain,
          website: input.website,
          tiendaNubeUrl: input.tiendaNubeUrl,
          mercadoShopsUrl: input.mercadoShopsUrl,
        };

        if (input.sectorId && Number(input.sectorId) > 0) {
          data.sectorId = Number(input.sectorId);
        }

        updateMutation({
          variables: {
            id: store.id,
            input: data,
          },
        });
      }
    },
    validationSchema: StoreSchema,
  });

  const connectMP = (storeId: number) => {
    const url = generateConnectUrl("mp", storeId);

    logEvent("mercado-pago.connect.account", {
      url,
    });

    window.location.href = url;
  };

  const connectML = (storeId: number) => {
    const url = generateConnectUrl("ml", storeId);

    logEvent("mercado-libre.connect.account", {
      url,
    });

    window.location.href = url;
  };

  const connectMS = (storeId: number) => {
    const url = generateConnectUrl("ms", storeId);

    logEvent("mercado-shops.connect.account", {
      url,
    });

    window.location.href = url;
  };

  const importImage = async () => {
    if (imageRef.current?.files?.length && !loading) {
      const image = imageRef.current.files[0];

      setLoading(true);

      const formData = new FormData();

      formData.append("file", image);

      try {
        await Api.Stores.uploadLogo(store.id, formData);

        refetch();
      } catch (e) {
        //
      } finally {
        if (imageRef.current) {
          imageRef.current.value = "";
        }

        setLoading(false);
      }
    }
  };

  const copyImage = async () => {
    if (!loading) {
      setLoading(true);

      const formData = new FormData();

      formData.append("companyId", store.companyId.toString());
      formData.append("copy", "true");

      try {
        await Api.Stores.uploadLogo(store.id, formData);

        refetch();
      } catch (e) {
        //
      } finally {
        setLoading(false);
      }
    }
  };

  const displayFooter =
    !(currentTab === 2 && store.type !== StoreType.Store) && canEditStore;

  const currentType = findPointOfSale(store.type);
  const StoreIcon = () => currentType?.icon;

  return (
    <>
      <CCard>
        <CCardHeader>Punto de Venta: {store.name}</CCardHeader>
        <CForm onSubmit={formik.handleSubmit}>
          <CCardBody>
            <CNav variant="tabs" role="tablist">
              <CNavItem>
                <CNavLink
                  onClick={() => onTabChange(0)}
                  active={currentTab === 0}
                >
                  Datos Básicos
                </CNavLink>
              </CNavItem>
              <CNavItem>
                <CNavLink
                  disabled={
                    store.type !== StoreType.Store ||
                    !hasPermission("LIST_REGISTERS")
                  }
                  onClick={() => onTabChange(1)}
                  active={currentTab === 1}
                >
                  Cajas Registradoras
                </CNavLink>
              </CNavItem>
              <CNavItem>
                <CNavLink
                  onClick={() => onTabChange(2)}
                  active={currentTab === 2}
                >
                  Listas de Precios
                </CNavLink>
              </CNavItem>
            </CNav>

            <CTabContent className="px-0">
              <CTabPane visible={currentTab === 0}>
                <CRow>
                  <CCol xs="12">
                    <CRow className="form-row">
                      <CCol md="3">
                        <CFormLabel>Empresa</CFormLabel>
                      </CCol>
                      <CCol xs="12" md="9">
                        <p className="form-control-static">
                          {store.company?.name}
                        </p>
                      </CCol>
                    </CRow>
                    <CRow className="form-row">
                      <CCol md="3">
                        <CFormLabel>Tipo de Punto de Venta</CFormLabel>
                      </CCol>
                      <CCol xs="12" md="9">
                        <StoreIcon />
                        <span className="ml-2">{currentType?.label}</span>
                      </CCol>
                    </CRow>
                    <CRow className="form-row">
                      <CCol md="3">
                        <CFormLabel htmlFor="name">Nombre</CFormLabel>
                      </CCol>
                      <CCol xs="12" md="9">
                        <CFormInput
                          defaultValue={formik.values.name}
                          id="name"
                          placeholder="Nombre"
                          name="name"
                          readOnly={!canEditStore}
                          onChange={formik.handleChange}
                          {...getValidity(
                            formik.values.name,
                            formik.errors.name
                          )}
                        />
                      </CCol>
                    </CRow>

                    {store.type === StoreType.Store && (
                      <>
                        <CRow className="form-row">
                          <CCol md="3">
                            <CFormLabel htmlFor="streetName">Calle</CFormLabel>
                          </CCol>
                          <CCol xs="12" md="9">
                            <CFormInput
                              defaultValue={formik.values.streetName}
                              id="streetName"
                              placeholder="Calle"
                              readOnly={!canEditStore}
                              name="streetName"
                              onChange={formik.handleChange}
                              {...getValidity(
                                formik.values.streetName,
                                formik.errors.streetName
                              )}
                            />
                          </CCol>
                        </CRow>
                        <CRow className="form-row">
                          <CCol md="3">
                            <CFormLabel htmlFor="streetNumber">
                              Altura
                            </CFormLabel>
                          </CCol>
                          <CCol xs="12" md="9">
                            <CFormInput
                              defaultValue={formik.values.streetNumber}
                              id="streetNumber"
                              placeholder="Altura"
                              readOnly={!canEditStore}
                              name="streetNumber"
                              onChange={formik.handleChange}
                              {...getValidity(
                                formik.values.streetNumber,
                                formik.errors.streetNumber
                              )}
                            />
                          </CCol>
                        </CRow>
                        <CRow className="form-row">
                          <CCol md="3">
                            <CFormLabel htmlFor="phone">Teléfono</CFormLabel>
                          </CCol>
                          <CCol xs="12" md="9">
                            <CFormInput
                              defaultValue={formik.values.phone}
                              id="phone"
                              placeholder="Teléfono"
                              name="phone"
                              readOnly={!canEditStore}
                              onChange={formik.handleChange}
                              {...getValidity(
                                formik.values.phone,
                                formik.errors.phone
                              )}
                            />
                          </CCol>
                        </CRow>
                        <CRow className="form-row">
                          <CCol md="3">
                            <CFormLabel htmlFor="website">Sitio Web</CFormLabel>
                          </CCol>
                          <CCol xs="12" md="9">
                            <CFormInput
                              defaultValue={formik.values.website}
                              id="website"
                              placeholder="Sitio Web"
                              name="website"
                              type="url"
                              readOnly={!canEditStore}
                              onChange={formik.handleChange}
                              {...getValidity(
                                formik.values.website,
                                formik.errors.website
                              )}
                            />
                          </CCol>
                        </CRow>
                        {sectors.length > 0 && (
                          <CRow className="form-row">
                            <CCol md="3">
                              <CFormLabel htmlFor="sectorId">Rubro</CFormLabel>
                            </CCol>
                            <CCol xs="12" md="9">
                              <CFormSelect
                                onChange={formik.handleChange}
                                name="sectorId"
                                defaultValue={formik.values.sectorId}
                              >
                                <option value="0">Seleccione sector</option>
                                {sectors.map((sector) => (
                                  <option key={sector.id} value={sector.id}>
                                    {sector.name}
                                  </option>
                                ))}
                              </CFormSelect>
                            </CCol>
                          </CRow>
                        )}

                        {user?.isAdmin && (
                          <CRow className="form-row">
                            <CCol md="3">
                              <CFormLabel htmlFor="subdomain">
                                Subdominio
                              </CFormLabel>
                            </CCol>
                            <CCol xs="12" md="9">
                              <CInputGroup>
                                <CInputGroupText>https://</CInputGroupText>
                                <CFormInput
                                  name="subdomain"
                                  placeholder="subdomain"
                                  onChange={formik.handleChange}
                                  defaultValue={formik.values.subdomain}
                                />
                                <CInputGroupText>.daruma.cloud</CInputGroupText>
                              </CInputGroup>
                            </CCol>
                          </CRow>
                        )}
                      </>
                    )}

                    {store.type === StoreType.TiendaNube && canEditStore && (
                      <>
                        <CRow className="form-row mt-2">
                          <CCol md="3">
                            <CFormLabel htmlFor="tiendaNube">
                              Tienda Nube
                            </CFormLabel>
                          </CCol>
                          <CCol xs="12" md="9">
                            <CFormSwitch
                              readOnly
                              checked={store.connections?.tiendaNubeConnected}
                              label={
                                store.connections?.tiendaNubeConnected
                                  ? "Conectado"
                                  : "Desconectado"
                              }
                            />

                            {store.connections?.tiendaNubeConnected ? (
                              <CLoadingButton
                                color="danger"
                                size="sm"
                                className="ml-2"
                                loading={disLoading}
                                disabled={disLoading}
                                onClick={() =>
                                  disconnectMutation({
                                    variables: {
                                      storeId: store.id,
                                    },
                                  })
                                }
                              >
                                Desconectar
                              </CLoadingButton>
                            ) : (
                              <CButton
                                color="success"
                                size="sm"
                                className="ml-2"
                                onClick={() => connectModalRef.current?.open()}
                              >
                                Conectar
                              </CButton>
                            )}
                          </CCol>
                        </CRow>

                        <CRow className="form-row">
                          <CCol md="3">
                            <CFormLabel htmlFor="tiendaNubeUrl">
                              URL Tienda Nube
                            </CFormLabel>
                          </CCol>
                          <CCol xs="12" md="9">
                            <CFormInput
                              placeholder="https://mitienda.tiendanube.com.ar"
                              name="tiendaNubeUrl"
                              defaultValue={store.metadata?.tiendaNubeUrl}
                              onChange={formik.handleChange}
                            />
                          </CCol>
                        </CRow>
                      </>
                    )}

                    {[StoreType.Digital, StoreType.Store].includes(
                      store.type
                    ) &&
                      canEditStore && (
                        <ConnectionRow
                          htmlFor="mercadoPago"
                          label="Mercado Pago"
                          value={!!store.connections?.mercadoPagoConnected}
                          onConnect={() => connectMP(store.id)}
                        />
                      )}

                    {store.type === StoreType.Rappi && canEditStore && (
                      <ConnectionRow
                        htmlFor="rappi"
                        label="Rappi"
                        value={!!store.connections?.rappiConnected}
                        onConnect={() => connectRappiModalRef.current?.open()}
                      />
                    )}

                    {store.type === StoreType.MercadoLibre && canEditStore && (
                      <ConnectionRow
                        htmlFor="mercadoLibre"
                        label="Mercado Libre"
                        value={!!store.connections?.mercadoLibreConnected}
                        onConnect={() => connectML(store.id)}
                      />
                    )}

                    {store.type === StoreType.MercadoShops && canEditStore && (
                      <>
                        <CRow className="form-row">
                          <CCol md="3">
                            <CFormLabel htmlFor="mercadoShopsUrl">
                              URL Mercado Shops
                            </CFormLabel>
                          </CCol>
                          <CCol xs="12" md="9">
                            <CFormInput
                              placeholder="https://mercadoshops.com.ar"
                              name="mercadoShopsUrl"
                              defaultValue={store?.metadata?.mercadoShopsUrl}
                              onChange={formik.handleChange}
                            />
                          </CCol>
                        </CRow>

                        <ConnectionRow
                          htmlFor="mercadoShops"
                          label="Mercado Shops"
                          value={!!store.connections?.mercadoShopsConnected}
                          onConnect={() => connectMS(store.id)}
                        />
                      </>
                    )}

                    {store.company.isFranchise && (
                      <CRow className="form-row">
                        <CCol md="3">
                          <CFormLabel htmlFor="utid">CUIT</CFormLabel>
                        </CCol>
                        <CCol xs="12" md="9">
                          <CFormInput
                            defaultValue={formik.values.utid}
                            id="utid"
                            placeholder="CUIT"
                            name="utid"
                            readOnly={!canEditStore}
                            onChange={formik.handleChange}
                            {...getValidity(
                              formik.values.utid,
                              formik.errors.utid
                            )}
                          />
                        </CCol>
                      </CRow>
                    )}

                    <CRow className="mt-3">
                      <CCol md="3">
                        <CFormLabel htmlFor="logoUrl">Logo</CFormLabel>
                      </CCol>
                      <CCol xs="12" md="9">
                        <CImage
                          thumbnail
                          src={store.logoUrl ?? "https://picsum.photos/200/200"}
                          width={156}
                          height={156}
                          onClick={() => {
                            imageRef.current?.click();
                          }}
                        />

                        {store.logoUrl !== store.company.logoUrl && (
                          <CLoadingButton
                            color="primary"
                            size="sm"
                            className="ml-2"
                            loading={loading}
                            onClick={copyImage}
                          >
                            Usar mismo que empresa
                          </CLoadingButton>
                        )}

                        <input
                          type="file"
                          style={{ display: "none" }}
                          ref={imageRef}
                          onChange={importImage}
                          accept=".jpeg,.jpg,.png"
                        />
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              </CTabPane>
              <CTabPane visible={currentTab === 1}>
                <CSmartTable
                  items={store?.registers || []}
                  columns={[
                    {
                      key: "name",
                      label: "Nombre",
                    },
                    {
                      key: "description",
                      label: "Descripción",
                    },
                    {
                      key: "status",
                      label: "Estado",
                    },
                    {
                      key: "openedAt",
                      label: "Abierta a las",
                    },
                  ]}
                  scopedColumns={{
                    description: (item: CashRegister) => (
                      <td>
                        <span>{item.description ?? " - "}</span>
                      </td>
                    ),
                    status: (item: CashRegister) => (
                      <td>
                        <span>{item.session ? "Abierta" : "Cerrada"}</span>
                      </td>
                    ),
                    openedAt: (item: CashRegister) => (
                      <td>
                        <span>
                          {item.session
                            ? dateFormat(
                                new Date(item.session.openedAt),
                                "dd/MM/yyyy HH:mm"
                              )
                            : " - "}
                        </span>
                      </td>
                    ),
                  }}
                  clickableRows
                  onRowClick={(item) => {
                    navigate(`/registers/${item.id}`);
                  }}
                  tableProps={{
                    striped: true,
                  }}
                />
              </CTabPane>
              <CTabPane visible={currentTab === 2}>
                <CSmartTable
                  items={store.priceLists ?? []}
                  columns={[
                    { key: "id", label: "ID" },
                    {
                      key: "name",
                      label: "Nombre",
                      _props: { className: "font-weight-bold" },
                    },
                    { key: "store", label: "Punto de Venta" },
                    { key: "dateFrom", label: "Fecha de Inicio" },
                    { key: "dateTo", label: "Fecha de Fin" },
                    { key: "updatedAt", label: "Última Actualización" },
                  ]}
                  scopedColumns={{
                    store: () => <td>{store.name}</td>,
                    dateFrom: (item: PriceList) => (
                      <td>
                        {dateFormat(new Date(item.dateFrom), "dd/MM/yyyy")}
                      </td>
                    ),
                    dateTo: (item: PriceList) => (
                      <td>
                        {item.dateTo
                          ? dateFormat(new Date(item.dateTo), "dd/MM/yyyy")
                          : "-"}
                      </td>
                    ),
                    updatedAt: (item: PriceList) => (
                      <td>
                        {dateFormat(
                          new Date(
                            store.type !== StoreType.Store && item.lastSyncAt
                              ? item.lastSyncAt
                              : item.updatedAt
                          ),
                          "dd/MM/yyyy HH:mm"
                        )}
                      </td>
                    ),
                  }}
                  tableProps={{
                    striped: true,
                    hover: true,
                  }}
                  clickableRows
                  onRowClick={(item) => navigate(`/price-lists/${item.id}`)}
                />
              </CTabPane>
            </CTabContent>
          </CCardBody>

          {displayFooter && (
            <CCardFooter>
              <CRow className="align-items-center">
                {currentTab === 0 && (
                  <>
                    <CCol md="6">
                      {(delError || updError) && (
                        <CAlert color="danger" className="my-0">
                          Ha ocurrido un error. Vuelve a intentar
                        </CAlert>
                      )}
                    </CCol>
                    <CCol md="6" className="flex justify-content-end">
                      {((hasPermission("DELETE_STORE") &&
                        store.type === StoreType.Store) ||
                        user?.isAdmin) && (
                        <>
                          <CButton
                            size="sm"
                            type="button"
                            color="danger"
                            className="mr-2"
                            onClick={() => setShowModal(true)}
                          >
                            Eliminar
                          </CButton>
                          <CModal
                            alignment="center"
                            visible={showModal}
                            onClose={() => setShowModal(false)}
                          >
                            <CModalHeader closeButton>
                              ¿Estás seguro de eliminar este punto de venta?
                            </CModalHeader>
                            <CModalFooter>
                              <CButton
                                size="sm"
                                color="secondary"
                                onClick={() => setShowModal(false)}
                              >
                                Cancelar
                              </CButton>
                              <CButton
                                size="sm"
                                color="danger"
                                onClick={onDelete}
                              >
                                Si, quiero eliminarlo
                              </CButton>
                            </CModalFooter>
                          </CModal>
                        </>
                      )}

                      {canEditStore && (
                        <CLoadingButton
                          size="sm"
                          color="primary"
                          loading={updLoading}
                          disabled={updLoading || delLoading || !formik.isValid}
                          type="submit"
                        >
                          Guardar
                        </CLoadingButton>
                      )}
                    </CCol>
                  </>
                )}
                {currentTab === 1 && (
                  <>
                    <CCol md="6" />
                    <CCol md="6" className="flex justify-content-end">
                      {hasPermission("CREATE_REGISTER") && (
                        <CButton
                          color="primary"
                          type="button"
                          size="sm"
                          onClick={() => {
                            logEvent("store.new-register", { store });

                            navigate("/registers/new", {
                              state: { storeId: store.id },
                            });
                          }}
                        >
                          Nueva caja
                        </CButton>
                      )}
                    </CCol>
                  </>
                )}
                {currentTab === 2 && store.type === StoreType.Store && (
                  <>
                    <CCol md="6" />
                    <CCol md="6" className="flex justify-content-end">
                      <CButton
                        size="sm"
                        color="primary"
                        type="button"
                        onClick={() => {
                          logEvent("store.new-price-list", { store });

                          navigate("/price-lists/new", {
                            state: { storeId: store.id },
                          });
                        }}
                      >
                        Nueva lista
                      </CButton>
                    </CCol>
                  </>
                )}
              </CRow>
            </CCardFooter>
          )}
        </CForm>
      </CCard>

      <ConnectModal storeId={store.id} ref={connectModalRef} />

      <ConnectRappiModal storeId={store.id} ref={connectRappiModalRef} />
    </>
  );
};

export default EditStore;
