import { useMutation, useQuery } from "@apollo/client";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CLoadingButton,
  CRow,
  CSmartTable,
} from "@coreui/react-pro";
import { useFormik } from "formik";
import { useMemo, useRef } from "react";
import Api from "src/api";
import { Product } from "src/api/products";
import { Retire, RetireItem } from "src/api/retires";

import { AppLoader } from "src/components/Loader/Loader";
import SmartSelect from "src/components/SmartSelect";
import { logEvent } from "src/helpers/analytics";
import { useAdminStore } from "src/store";
import { GraphQLFind, GraphQLMeta } from "src/types";
import yup from "src/helpers/validation";
import { dateFormat } from "src/helpers/dates";
import { formatCurrency } from "src/helpers/numbers";
import { ChevronLeft, LoaderCircle, Trash } from "lucide-react";

export const RemoveStockSchema = yup.object().shape({
  productId: yup.number().min(1).required(),
  quantity: yup.number().min(0.1).required(),
});

const StockList = ({
  retireId,
  goBack,
  warehouseId,
}: {
  retireId?: number;
  goBack: () => void;
  warehouseId?: number;
}) => {
  const quantityRef = useRef<HTMLInputElement>(null);
  const { hasPermission, currentCompany } = useAdminStore();
  const { data: productsList } = useQuery<GraphQLMeta<Product>>(
    Api.Products.LIST_PRODUCTS,
    {
      variables: {
        filters: {
          companyId: currentCompany?.id,
          warehouseId,
          limit: 0,
          trashed: 0,
          stockeable: true,
        },
      },
    }
  );
  const { data: retire, refetch } = useQuery<GraphQLFind<Retire>>(
    Api.Retires.GET_RETIRE,
    {
      variables: {
        id: retireId,
      },
      skip: !retireId,
      fetchPolicy: "no-cache",
    }
  );
  const [addItem, { loading: adding }] = useMutation(
    Api.Retires.ADD_ITEM_TO_RETIRE,
    {
      onCompleted: () => {
        (document.querySelector(".select-search-input") as any).focus();
        refetch();
        formik.resetForm();
      },
    }
  );
  const [deleteRetireItem, { loading: deleting }] = useMutation(
    Api.Retires.DELETE_ITEM_FROM_RETIRE,
    {
      onCompleted: () => {
        refetch();
        formik.resetForm();
      },
    }
  );
  const [closeRetire, { loading: closing }] = useMutation(
    Api.Retires.CLOSE_RETIRE,
    {
      onCompleted: () => {
        refetch();
        formik.resetForm();
      },
    }
  );
  const [fulfillRetire, { loading: fulfilling }] = useMutation(
    Api.Retires.FULFILL_RETIRE,
    {
      onCompleted: () => {
        refetch();
        formik.resetForm();
      },
      onError: (data) => {
        if (data.message === "retire_cant_be_fulfilled") {
          alert("Este retiro ya se encuentra descargado de tu depósito.");

          window.location.reload();
        }
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      productId: 0,
      currentStock: 0,
      quantity: 0,
    },
    onSubmit: (data) => {
      const input = {
        retireId: retireId,
        productId: data.productId,
        quantity: data.quantity,
      };

      logEvent("retires.add-item", { input });

      addItem({
        variables: {
          input,
        },
      });
    },
    validateOnMount: true,
    validationSchema: RemoveStockSchema,
  });

  const products = useMemo(() => {
    if (!productsList?.data.data) {
      return [];
    }

    return productsList.data.data.map((product) => ({
      name: `${product.name} - ${
        product.barcodes.length > 0
          ? product.barcodes.map((b) => b.barcode).join(", ")
          : "Sin código de barra"
      } - (Stock: ${product.currentStock})`,
      value: product.id,
    }));
  }, [productsList]);

  const { totals, amount } = retire?.data.items.reduce(
    (prev, curr) => ({
      totals: curr.quantity + prev.totals,
      amount: curr.quantity * curr.product.price + prev.amount,
    }),
    { totals: 0, amount: 0 }
  ) ?? { totals: 0, amount: 0 };

  if (!retire?.data) {
    return <AppLoader />;
  }

  const columns = [
    {
      key: "product",
      label: "Producto",
      _props: { className: "font-weight-bold" },
    },
    {
      key: "user",
      label: "Cargado por",
      _props: { className: "text-center font-weight-bold" },
    },
    {
      key: "quantity",
      label: "Cantidad",
      _props: { className: "text-center font-weight-bold" },
    },
  ];

  const retireStatus = retire.data.status;
  const canUpdateRetire =
    retire.data.warehouse.stores?.length > 0
      ? retire.data.warehouse.stores.some(({ id }) =>
          hasPermission("UPDATE_RETIRE", { type: "store", id })
        )
      : hasPermission("UPDATE_RETIRE", {
          type: "company",
          id: retire.data.warehouse.companyId,
        });

  if (retireStatus === "OPEN") {
    columns.push({
      key: "actions",
      label: "Acciones",
      _props: { className: "text-right font-weight-bold" },
    });
  }

  if (retireStatus === "FULFILLED") {
    columns.push({
      key: "price",
      label: "Precio Unitario",
      _props: { className: "text-right font-weight-bold" },
    });

    columns.push({
      key: "amount",
      label: "Subtotal",
      _props: { className: "text-right font-weight-bold" },
    });
  }

  const generateFooter = () => {
    if (totals <= 0) {
      return false;
    }

    const footerStack = [
      {
        label: "Cantidad de items / Total en $",
      },
      "",
      {
        label: totals?.toString(),
        _props: { className: "text-center" },
      },
      "",
    ];

    if (retireStatus === "FULFILLED") {
      footerStack.push({
        label: formatCurrency(amount),
        _props: { className: "text-right" },
      });
    }

    return footerStack;
  };

  return (
    <CCard className="print-table">
      <CCardHeader>
        <CRow className="px-0">
          <CCol sm={6} className="px-0 d-flex align-items-center">
            <CButton
              onClick={goBack}
              variant="ghost"
              color="default"
              size="sm"
              className="no-print"
            >
              <ChevronLeft color="black" />
            </CButton>
            {canUpdateRetire &&
              retireStatus === "OPEN" &&
              `Cargar egreso en ${retire.data.warehouse.name}`}
            {(!canUpdateRetire || retireStatus) !== "OPEN" &&
              `Egreso #${retire.data.id} ${
                retire.data.notes ? ` - ${retire.data.notes}` : ""
              } en ${retire.data.warehouse.name}`}
          </CCol>
          <CCol sm={6} className="text-right">
            {canUpdateRetire && (
              <>
                {retireStatus === "OPEN" && (
                  <CLoadingButton
                    loading={closing}
                    onClick={() =>
                      closeRetire({
                        variables: {
                          id: retireId,
                        },
                      })
                    }
                    size="sm"
                    color="info"
                  >
                    Cerrar egreso
                  </CLoadingButton>
                )}

                {retireStatus === "CLOSED" && (
                  <CLoadingButton
                    loading={fulfilling}
                    onClick={() =>
                      fulfillRetire({
                        variables: {
                          id: retireId,
                        },
                      })
                    }
                    size="sm"
                    color="success"
                  >
                    Retirar de depósito
                  </CLoadingButton>
                )}
              </>
            )}

            {retireStatus === "FULFILLED" && (
              <>
                <span className="only-print">
                  Retirado el{" "}
                  {dateFormat(retire.data.updatedAt, "dd/MM/yyyy HH:mm")}
                </span>
                <CButton
                  onClick={() => {
                    logEvent("retires.print", {
                      retireId,
                    });

                    window.print();
                  }}
                  size="sm"
                  color="success"
                  className="no-print"
                >
                  Imprimir
                </CButton>
              </>
            )}
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        {canUpdateRetire && retireStatus === "OPEN" && (
          <CForm className="mb-3">
            <CRow>
              <CCol className="product-search" sm={6}>
                <CFormLabel>Producto</CFormLabel>
                <SmartSelect
                  name="productId"
                  search
                  placeholder="Selecciona un producto"
                  emptyLabel="No tienes productos stockeables"
                  options={products}
                  onChange={(e) => {
                    const product = productsList?.data.data.find(
                      (p) => p.id === e
                    );

                    const currentRetireStock =
                      retire.data.items?.find((s) => s.product.id === e)
                        ?.quantity ?? 0;

                    if (product) {
                      const newStock =
                        product.currentStock - currentRetireStock;

                      formik.setValues({
                        productId: product.id,
                        currentStock: newStock,
                        quantity: newStock,
                      });

                      quantityRef.current?.focus();
                    }
                  }}
                  value={formik.values.productId.toString()}
                />
              </CCol>
              <CCol sm={3}>
                <CFormLabel>Cantidad</CFormLabel>
                <CFormInput
                  value={formik.values.quantity}
                  name="quantity"
                  ref={quantityRef}
                  type="number"
                  readOnly={
                    formik.values.quantity.toString() !== "" &&
                    formik.values.quantity <= 0
                  }
                  onChange={formik.handleChange}
                  valid={
                    !!formik.values.productId &&
                    formik.values.quantity > 0 &&
                    formik.values.quantity <= formik.values.currentStock
                  }
                  invalid={
                    !!formik.values.productId &&
                    !(
                      formik.values.quantity > 0 &&
                      formik.values.quantity <= formik.values.currentStock
                    )
                  }
                  feedbackInvalid={
                    formik.values.currentStock < 0
                      ? "No puedes retirar stock de un producto con stock 0 o negativo"
                      : "No puedes retirar mas stock de este producto"
                  }
                />
              </CCol>
              <CCol
                sm={3}
                className="d-flex align-items-start mt-9 justify-content-end"
              >
                <CLoadingButton
                  color="primary"
                  type="submit"
                  size="sm"
                  onClick={formik.handleSubmit}
                  loading={adding}
                  disabled={adding || !formik.isValid}
                >
                  Agregar
                </CLoadingButton>
              </CCol>
            </CRow>
          </CForm>
        )}
        <CSmartTable
          footer={generateFooter()}
          itemsPerPage={retire.data.items.length ?? 0}
          items={retire.data.items ?? []}
          columns={columns}
          noItemsLabel={
            retire.data.status === "OPEN"
              ? "Empieza a agregar productos al egreso"
              : "No tienes items en este egreso"
          }
          scopedColumns={{
            product: (item: RetireItem) => <td>{item.product.name}</td>,
            user: (item: RetireItem) => (
              <td align="center">
                {item.user.name} {item.user.lastname}
              </td>
            ),
            quantity: (item: RetireItem) => (
              <td align="center">{item.quantity}</td>
            ),
            price: (item: RetireItem) => (
              <td align="right">{formatCurrency(item.product.price)}</td>
            ),
            amount: (item: RetireItem) => (
              <td align="right">
                {formatCurrency(item.product.price * item.quantity)}
              </td>
            ),
            actions: (item: RetireItem) => (
              <td align="right">
                <CButton
                  onClick={() =>
                    deleteRetireItem({ variables: { id: item.id } })
                  }
                  size="sm"
                  color="danger"
                >
                  {deleting ? <LoaderCircle /> : <Trash />}
                </CButton>
              </td>
            ),
          }}
        />
      </CCardBody>
    </CCard>
  );
};

export default StockList;
