import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CSmartTable,
  CRow,
  CForm,
  CFormInput,
  CButton,
  CLoadingButton,
  CCardFooter,
} from "@coreui/react-pro";

import { useMutation, useQuery } from "@apollo/client";

import { formatCurrency } from "src/helpers/numbers";

import { useFormik } from "formik";
import { usePagination } from "src/hooks/pagination";
import { GraphQLMeta, SearchForm } from "src/types";
import Api from "src/api";
import { queryStringToObject } from "src/helpers/strings";
import PlanAlert from "src/containers/PlanAlert";
import Pagination from "src/components/Pagination/Pagination";
import { PriceListItem } from "src/api/price-lists";
import { TextSearch } from "lucide-react";

const MercadoLibreAudit = () => {
  const navigate = useNavigate();
  const querySearch = queryStringToObject(useLocation().search);
  const params = useParams();
  const storeId = Number(params.storeId);

  if (!storeId) {
    navigate("/mercado-libre");
  }

  const {
    data: items,
    refetch,
    loading,
  } = useQuery<GraphQLMeta<PriceListItem>>(
    Api.PriceLists.LIST_DIFFERENT_ITEMS,
    {
      variables: {
        filters: {
          storeId,
          page: querySearch.page ? Number(querySearch.page) : 1,
          search: querySearch.search ?? "",
        },
      },
    }
  );
  const { page, pageChange, resetAndSearch } = usePagination(
    `mercado-libre/${storeId}/products`,
    refetch
  );

  const [updateMutation, { loading: updating }] = useMutation(
    Api.MercadoLibre.SYNC_PRODUCT,
    {
      onCompleted: () => {
        window.location.reload();
      },
    }
  );

  const [syncMutation, { loading: syncing }] = useMutation(
    Api.MercadoLibre.SYNC_PRODUCTS,
    {
      onCompleted: () => {
        window.location.reload();
      },
    }
  );

  const formik = useFormik<SearchForm>({
    initialValues: {
      search: querySearch.search ?? "",
    },
    onSubmit: (input) => {
      if (!loading) {
        resetAndSearch({
          search: input.search ?? "",
          page: 1,
          storeId,
        });
      }
    },
  });

  return (
    <>
      <PlanAlert />
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader>
              <CRow className="align-items-center justify-content-center">
                <CCol sm="6" xs="6" className="px-0">
                  Productos desactualizados
                </CCol>
                <CCol sm="6" xs="6" className="row justify-content-end"></CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CForm onSubmit={formik.handleSubmit} className="mb-3">
                <CRow className="align-items-center justify-content-center">
                  <CCol
                    sm={12}
                    className="row justify-content-end"
                    style={{ flexWrap: "nowrap" }}
                  >
                    <CFormInput
                      placeholder="Buscar por Nombre"
                      name="search"
                      autoComplete="false"
                      defaultValue={formik.values.search}
                      onChange={formik.handleChange}
                      style={{ flex: 1 }}
                    />
                    <CButton
                      type="submit"
                      color="primary"
                      className="ml-2 p-0 w-10 h-10"
                    >
                      <TextSearch />
                    </CButton>
                  </CCol>
                </CRow>
              </CForm>

              <CSmartTable
                itemsPerPage={20}
                items={items?.data.data ?? []}
                columns={[
                  { key: "id", label: "ID" },
                  {
                    key: "name",
                    label: "Nombre",
                    _props: { className: "font-weight-bold" },
                  },
                  {
                    key: "price",
                    label: "Precio de Producto",
                    _props: { className: "text-right" },
                  },
                  {
                    key: "mercadoLibrePrice",
                    label: "Precio en Mercado Libre",
                    _props: { className: "text-right" },
                  },
                  {
                    key: "actions",
                    label: "Acciones",
                    _props: { className: "text-right" },
                  },
                ]}
                loading={loading}
                scopedColumns={{
                  name: (item: PriceListItem) => <td>{item.product.name}</td>,
                  cost: (item: PriceListItem) => (
                    <td align="right">{formatCurrency(item.product.cost)}</td>
                  ),
                  price: (item: PriceListItem) => (
                    <td align="right">{formatCurrency(item.product.price)}</td>
                  ),
                  mercadoLibrePrice: (item: PriceListItem) => (
                    <td align="right">
                      {formatCurrency(
                        item.mercadoLibreProduct?.price ?? item.price
                      )}
                    </td>
                  ),
                  actions: (item: PriceListItem) => (
                    <td align="right">
                      <CLoadingButton
                        size="sm"
                        loading={updating}
                        disabled={updating}
                        color="success"
                        onClick={() => {
                          updateMutation({
                            variables: {
                              itemId: item.id,
                            },
                          });
                        }}
                      >
                        {updating ? "Actualizando" : "Actualizar"}
                      </CLoadingButton>
                    </td>
                  ),
                }}
                tableProps={{
                  striped: true,
                  hover: true,
                }}
              />

              <Pagination meta={items} page={page} pageChange={pageChange} />
            </CCardBody>
            <CCardFooter>
              <CRow>
                <CCol sm={12} className="text-right">
                  {(items?.data.data.length ?? 0) > 1 && (
                    <CLoadingButton
                      size="sm"
                      color="primary"
                      loading={syncing}
                      disabled={syncing}
                      onClick={() =>
                        syncMutation({
                          variables: {
                            itemIds: items?.data.data.map((item) => item.id),
                          },
                        })
                      }
                    >
                      Actualizar todos
                    </CLoadingButton>
                  )}
                </CCol>
              </CRow>
            </CCardFooter>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default MercadoLibreAudit;
