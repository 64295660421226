import { forwardRef, useImperativeHandle, useState } from "react";
import {
  CButton,
  CFormInput,
  CModal,
  CModalHeader,
  CModalFooter,
  CModalBody,
  CForm,
  CRow,
  CCol,
  CFormLabel,
} from "@coreui/react-pro";

import { useMutation, useQuery } from "@apollo/client";
import { PriceListItemCreation } from "src/api/price-lists";
import { formatCurrency } from "src/helpers/numbers";
import { Product } from "src/api/products";
import { useFormik } from "formik";
import { GraphQLMeta, OnChangeEvent } from "src/types";
import Api from "src/api";
import { getValidity } from "src/helpers/validation";
import { Check } from "lucide-react";

const AddItemModal = ({ priceListId }, ref) => {
  const [query, setQuery] = useState<string>("");
  const [currentProduct, setCurrentProduct] = useState<Product | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);

  const [createMutation, { loading }] = useMutation(
    Api.PriceLists.CREATE_PRICE_LIST_ITEM,
    {
      onCompleted: () => {
        window.location.reload();
      },
    }
  );

  const { data: resultProducts } = useQuery<GraphQLMeta<Product>>(
    Api.Products.LIST_PRODUCTS,
    {
      fetchPolicy: "no-cache",
      variables: {
        filters: {
          notInPriceList: priceListId,
          limit: 10,
          search: query,
        },
      },
    }
  );

  const onProductValueChange = (e: OnChangeEvent) => {
    setCurrentProduct(null);
    setQuery(e.currentTarget.value);
  };

  const selectProduct = (selectedProduct: Product) => {
    setQuery("");
    setCurrentProduct(selectedProduct);
    formik.setFieldValue("name", selectedProduct.name);
    formik.setFieldValue("price", selectedProduct.price);
  };

  useImperativeHandle(
    ref,
    () => ({
      open: () => setShowModal(true),
      close: () => setShowModal(false),
    }),
    []
  );

  const formik = useFormik<PriceListItemCreation>({
    initialValues: {
      name: "",
      price: 0,
      description: currentProduct?.description ?? "",
      priceListId,
      productId: currentProduct?.id ?? 0,
      tax: currentProduct?.tax ?? 0,
    },
    onSubmit: (input) => {
      if (currentProduct && !loading) {
        const { name, price, description, tax } = currentProduct;

        createMutation({
          variables: {
            input: {
              name: input.name ?? name,
              price: input.price ? Number(input.price) : Number(price),
              description: input.description ?? description,
              priceListId: input.priceListId ?? priceListId,
              productId: currentProduct.id,
              tax: input.tax ?? tax,
            },
          },
        });
      }
    },
  });

  return (
    <CForm>
      <CModal
        alignment="center"
        size="lg"
        visible={showModal}
        onClose={() => {
          setShowModal(false);
          setCurrentProduct(null);
          formik.resetForm();
        }}
      >
        <CModalHeader closeButton>
          Agregar producto a la lista de precios
        </CModalHeader>
        <CModalBody>
          <CFormInput
            placeholder="Buscar producto..."
            onChange={onProductValueChange}
          />

          {(resultProducts?.data.meta.total ?? 0) > 0 && (
            <div className="mt-3">
              {resultProducts?.data.data.map((product) => (
                <div
                  style={{
                    height: 36,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: 12,
                  }}
                  key={product.id}
                >
                  <span className="flex">
                    {product.name}{" "}
                    {product.barcodes.length > 0
                      ? ` - ${product.barcodes
                          ?.map(({ barcode }) => barcode)
                          .join(", ")}`
                      : ""}
                  </span>

                  <span className="mr-3">{formatCurrency(product.price)}</span>

                  <CButton
                    type="button"
                    color="primary"
                    onClick={() => selectProduct(product)}
                  >
                    <Check />
                  </CButton>
                </div>
              ))}
            </div>
          )}

          {(resultProducts?.data.meta.total ?? 0) === 0 && query !== "" && (
            <div className="mt-3">
              <div
                style={{
                  height: 30,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "justify-center",
                }}
              >
                <span style={{ textAlign: "center", width: "100%" }}>
                  No tienes productos para agregar
                </span>
              </div>
            </div>
          )}

          {currentProduct && (
            <div className="mt-3">
              <CRow className="form-row">
                <CCol md="4" className="align-items-center d-flex">
                  <CFormLabel htmlFor="name" className="mb-0">
                    Nombre
                  </CFormLabel>
                </CCol>
                <CCol xs="12" md="8">
                  <CFormInput
                    id="name"
                    defaultValue={formik.values.name}
                    name="name"
                    onChange={formik.handleChange}
                    {...getValidity(formik.values.name, formik.errors.name)}
                  />
                </CCol>
              </CRow>

              <CRow className="form-row">
                <CCol md="4" className="align-items-center d-flex">
                  <CFormLabel htmlFor="price" className="mb-0">
                    Precio
                  </CFormLabel>
                </CCol>
                <CCol xs="12" md="8">
                  <CFormInput
                    id="price"
                    defaultValue={formik.values.price}
                    name="price"
                    onChange={formik.handleChange}
                    {...getValidity(formik.values.price, formik.errors.price)}
                  />
                </CCol>
              </CRow>
            </div>
          )}
        </CModalBody>

        <CModalFooter>
          <CButton
            type="button"
            size="sm"
            color="secondary"
            onClick={() => setShowModal(false)}
          >
            Cancelar
          </CButton>
          <CButton
            type="submit"
            size="sm"
            onClick={() => formik.handleSubmit()}
            disabled={!!currentProduct === false || loading}
            color="success"
          >
            Agregar producto
          </CButton>
        </CModalFooter>
      </CModal>
    </CForm>
  );
};
export default forwardRef(AddItemModal);
